import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Config } from '../context';

import { abudhabi, bahrain, dubai, kuwait, saudiarabia, oman, qatar, jordan, lebanon } from '../json/offers';

import regions from '../json/regions.json';

export default function useRegion(){

    // context
    const config = useContext(Config);

    const router = useParams();

	let href = window.location.href;

	if(href.indexOf('r=az') > -1 || href.indexOf('azar') > -1 || href.indexOf('abudhabi') > -1 || href.indexOf('abu-dhabi') > -1 || config.region === 'abudhabi' || router.region === 'abudhabi'){
        return {
            slug:"abudhabi",
            offers:abudhabi,
            ecommerce:true,
            data:regions['abudhabi']
        }
	}else if(href.indexOf('r=bh') > -1 || href.indexOf('bhar') > -1 || href.indexOf('bahrain') > -1 || config.region === 'bahrain' || router.region === 'bahrain'){
        return {
            slug:"bahrain",
            offers:bahrain,
            data:regions['bahrain'],
        }
	}else if(href.indexOf('r=du') > -1 || href.indexOf('duar') > -1 || href.indexOf('dubai') > -1 || config.region === 'dubai' || router.region === 'dubai'){
        return {
            slug:"dubai",
            offers:dubai,
            ecommerce:true,
            data:regions['dubai']
        }
	}else if(href.indexOf('r=kw') > -1 || href.indexOf('kwar') > -1 || href.indexOf('kuwait') > -1 || config.region === 'kuwait' || router.region === 'kuwait'){
        return {
            slug:"kuwait",
            offers:kuwait,
            data:regions['kuwait']
        }
	}else if(href.indexOf('r=sa') > -1 || href.indexOf('saar') > -1 || href.indexOf('saudiarabia') > -1 || href.indexOf('saudi-arabia') > -1 || config.region === 'saudiarabia' || router.region === 'saudiarabia'){
        return {
            slug:"saudiarabia",
            offers:saudiarabia,
            data:regions['saudiarabia']
        }
	}else if(href.indexOf('r=om') > -1 || href.indexOf('omar') > -1 || href.indexOf('oman') > -1 || config.region === 'oman' || router.region === 'oman'){
        return {
            slug:"oman",
            offers:oman,
            data:regions['oman']
        }
	}else if(href.indexOf('r=qa') > -1 || href.indexOf('qaar') > -1 || href.indexOf('qatar') > -1 || config.region === 'qatar' || router.region === 'qatar'){
        return {
            slug:"qatar",
            offers:qatar,
            ecommerce:true,
            data:regions['qatar']
        }
	}else if(href.indexOf('r=jo') > -1 || href.indexOf('joar') > -1 || href.indexOf('jordan') > -1 || config.region === 'jordan' || router.region === 'jordan'){
        return {
            slug:"jordan",
            offers:jordan,
            ecommerce:true,
            data:regions['jordan']
        }
	}else if(href.indexOf('r=lb') > -1 || href.indexOf('lbar') > -1 || href.indexOf('lebanon') > -1 || config.region === 'lebanon' || router.region === 'lebanon'){
        return {
            slug:"lebanon",
            offers:lebanon,
            data:regions['lebanon']
        }
	}
	return false;
}