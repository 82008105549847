import styled from 'styled-components';
import { mobi, tablet, mid, desktop } from '../../theme/mixins/index.js';

const Styled = styled.div`
    overflow: hidden;
    position: relative;
    background:black;
    scroll-snap-align: start;
    &.loaded {
        .media {
            img {
                opacity:1;
            }
        }
    }
    > .media {
        z-index:0;
        overflow: hidden;
        width: 100%;
        height:560px;

        ${mobi`
            height:640px;
        `};

        ${tablet`
            height:700px;
        `};

        ${mid`
            height:720px;
        `};

        ${desktop`
            height:760px;
        `};

        @media (min-width:1660px){
            height:850px;
        }
        
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            opacity:0;
            display:block;
            transition:.4s opacity cubic-bezier(0.850, 0.020, 0.500, 1.000);
        }
    }
    > .content {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: -moz-linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7) 100%);
        background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 74%, rgba(0,0,0,0.7) 100%);

        h1,h2 {
            text-align:center;
        }

        h1 {
            span {
                display:inline-block;
                font-size:min(5.5vw,28px);
                line-height:1.4;
                ${tablet`
                    font-size:min(6vw,36px);
                    line-height:1.4;
                `}
            }
        }

        .btn-group {
            gap:6px;
            button, a {
                white-space:nowrap;
                &.red {
                    background:var(--red);
                    border-color:var(--red);
                    color:white;
                    &:hover{
                        background:var(--red-hover);
                        border-color:var(--red-hover);
                    }
                }
            }
        }
        
        .offers {
            gap:14px;
            margin-bottom:10px;
            ${mid`
                gap:24px;
            `};
            .offer {
                display:flex;
                align-items:stretch;
                .inner {
                    gap:8px;
                }
                .divider {
                    height:auto;
                    width:1px; 
                    background:white;
                    margin-left:14px;
                    opacity:0;
                    ${mid`
                        margin-left:24px;
                        opacity:0;
                    `}; 
                }

                h2 {
                    font-size:min(max(32px, 8vw), 52px);
                    line-height:1;
                    .first {
                        display: inline-block;
                        transform: translate(-10%,-16%);
                    }
                }
                h3 {
                    font-size:min(max(16px, 3vw), 36px);
                    line-height:1;
                }
                p {
                    font-size:min(max(13px, 1.4vw), 18px);
                    line-height:1.2;
                }

            }
        }

    }
    .drawer {
        background:#f2f2f2;
    }

    .stock {
        position:relative;
        padding-top:5px;
        .actions {
            position:relative;
            top:0;
            width:100%;
            justify-content:flex-end;
            display:flex;
            z-index:9;
            ${tablet`
                position:absolute;
                transform:translateY(-100%);
            `};
            > button {
                appearance: none;
                outline: 0;
                background: transparent;
                border: 0;
                background:white;
                @media (max-width:768px){
                    background:transparent;
                    svg {
                        width: 32px;
                        height: 32px;
                        margin-bottom: -14px;
                        color:black;
                    }
                }
            }
        }
    }

    ${props => props.small && `
        .content {
            .offers {
                .offer {
                    h2 {
                        font-size:min(max(32px, 8vw), 42px);
                        line-height:1;
                    }
                    p {
                        font-size:min(max(13px, 1.4vw), 16px);
                        line-height:1.2;
                    }
                    .divider {
                        opacity:0;
                    }
                }
            }
        }
    `}

    [dir="rtl"] & {
        .content {
            .offers {
                .offer {
                    .divider {
                        margin-right:0!important;
                        margin-left:14px;
                        ${mid`
                            margin-left:24px;
                        `}; 
                    }
                }
            }
        }
    }
`;

export default Styled;
