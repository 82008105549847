import az from './abudhabi.json';
import bh from './bahrain.json';
import du from './dubai.json';
import kw from './kuwait.json';
import sa from './saudiarabia.json';
import om from './oman.json';
import qa from './qatar.json';
import jo from './jordan.json';
import lb from './lebanon.json';

export const abudhabi = az;
export const bahrain = bh;
export const dubai = du;
export const kuwait = kw;
export const saudiarabia = sa;
export const oman = om;
export const qatar = qa;
export const jordan = jo;
export const lebanon = lb;