import { css } from "styled-components";

export const mobi = (...args) => css`
    @media (min-width: 540px) {
        ${css(...args)};
    }
`;
export const tablet = (...args) => css`
    @media (min-width: 768px) {
        ${css(...args)};
    }
`;
export const mid = (...args) => css`
    @media (min-width: 1024px) {
        ${css(...args)};
    }
`;
export const middesktop = (...args) => css`
    @media (min-width: 1280px) {
        ${css(...args)};
    }
`;
export const desktop = (...args) => css`
    @media (min-width: 1440px) {
        ${css(...args)};
    }
`;
export const max = (...args) => css`
    @media (min-width: 1920px) {
        ${css(...args)};
    }
`;