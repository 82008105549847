export default function useArabic(){

	let href = window.location.href;

    if(href.indexOf('l=ar') > -1 || href.indexOf('/ar') > -1 || href.indexOf('ar.audi') > -1){
        return { 
            arabic: true, 
            lang: 'ar'
        };
    }

    return { 
        arabic: false, 
        lang: 'en'
    };
}
