// Apple Pay?
// Style pay pages
// padding 1920
// initial drawer dont animate
// hide if no data

import React, { useState, useCallback, useEffect } from 'react';

import { createOneGraphClient, OneGraphProvider } from '@volkswagen-onehub/onegraph-client';
import { useQuery } from '@volkswagen-onehub/onegraph-client';
import Query from './query';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import isEmail from 'email-validator';
import PayBtn from 'payment';

import { AudiPlatformProvider, Layout, LayoutItem, Loader, Text, Button, Divider, List, ListItem, Tag, FormGroup, TextField, Checkbox } from '@audi/audi-ui-react';

import useArabic from './hooks/useArabic';
import useBreaks from './hooks/useBreaks';
import regions from './json/regions.json';

import Styled from './theme/styles';
import Modal from './theme/modal';

import locale from './json/locale';

function Checkout({settings,car,region,closeCheckout,setTerms,setHow}){

    const engine = car?.catalog.models.data.find(engine => engine.status.selected);
    const { lang } = useArabic();
    const mq = useBreaks();

    // params
    const params = new URLSearchParams(window.location.search),
        entries = params.entries(),
        utmStorage = localStorage.getItem('saved_utm');

        let formparams = '';
        let campaign = '';

        if(utmStorage){
            let utm = JSON.parse(utmStorage),
                entries = utm.query.split('&');

            for(var entry of entries){
                if(entry.indexOf('utm_') > -1){
                    formparams += '['+entry+']';
                }
                if(entry[0] === 'campaign' || entry[0] === 'utm_campaign'){
                    campaign = entry[1];
                }
            } 
        }else {
            for(const entry of entries){
                formparams += '['+entry[0]+'='+entry[1]+']';
                if(entry[0] === 'campaign' || entry[0] === 'utm_campaign'){
                    campaign = entry[1];
                }
            }
        }

    const [form,setForm] = useState({
        email:'',
        phone:'',
        consent:'no'
    }),
    [ready,setReady] = useState(false),
    [emailErr,setEmailErr] = useState(false),
    [phoneErr,setPhoneErr] = useState(false),
    [consentErr,setConsentErr] = useState(true);
    
    // payload
    const payload = {
        price:!car?.price.full ? 'Not specified':car?.price.full,
        deposit:car?.price.deposit,
        type:'Online Model Reservation Order',
        model:car?.name,
        slug:car?.slug,
        selection:`${car?.name} | ${car?.year} | VIN: ${car?.vin}`,
        vin:car?.vin,
        email:form.email,
        phone:`+ ${region.code} ${form.phone}`,
        campaign:campaign,
        language:lang,
        params:formparams,
        callback:settings.callback
    }

    // onchange
    const onChange = (e) => {
		let { id,value } = e.currentTarget;
        form[id] = value;
        if(id.indexOf('consent') > -1){
            if(value === 'yes'){
                form[id] = 'no';
            }else {
                form[id] = 'yes';
            }
        }
		setForm({...form});
        validate(id,value);
	}

    // validate
	const validate = (name,value) => {
        
        const validateNumber = (number) => {
            if(region.name === 'abudhabi' || region.name === 'dubai'){
                if(number.length === 9 && number.match("^5")){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'bahrain'){
                if(number.length > 5 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'kuwait'){
                if(number.length > 5 && number.length < 11){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'saudiarabia'){
                if(number.length > 6){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'oman'){
                if(number.length === 8){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'qatar'){
                if(number.length > 6 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'jordan'){
                if((number.length === 8 && number.match('^6')) || (number.length === 9 && number.match('^7'))){
                    return true;
                }else {
                    return false;
                }
            }else if(region.name === 'lebanon'){
                if(number.length > 6 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }
        }

        let reg = /^(?!.*\.{2})([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/,
            items = Object.entries(form),
            empty = items.filter(item => item[1] === '').length ? true:false,
            email = !isEmail.validate(Object.entries(form).find(item => item[0] === 'email')[1]),
            phone = !validateNumber(items.find(item => item[0] === 'phone')[1]),
            consent = Object.entries(form).find(item => item[0] === 'consent')[1] === 'no' ? true:false;
            
            // now errors on specific items
            if(name === 'email'){
                if(email && value !== ''){
                    setEmailErr(true)
                }else {
                    setEmailErr(false)
                }
            }
            if(name === 'phone'){
                if(phone && value !== ''){
                    setPhoneErr(true)
                }else {
                    setPhoneErr(false)
                }
            }
            if(name === 'consent'){
                if(consent){
                    setConsentErr(true)
                }else {
                    setConsentErr(false)
                }
            }
            // return
            if(!empty && !email && !phone && !consent){
                setReady(true);
                return false;
            }
            setReady(false)

	}

    // carousel
    const [active,setActive] = useState(0);
    useEffect(() => {
        let interval = null;
        if(!mq.mid){
            interval = setInterval(()=>{
                if(active < 2){
                    setActive(active+1);
                }else {
                    setActive(0);
                }
            },2400);
            return () => clearTimeout(interval)
        }
    }, [active]);

    return (
        car &&
        <div className="checkout" shrink="0">
            <Layout className="inner" wrap="wrap">
                <LayoutItem className="content" basis={mq.mid ? '100%':'32%'}>
                    {
                        !mq.mid &&
                        <Layout className="close" justify="end">
                            <div onClick={closeCheckout}>
                                <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </div>
                        </Layout>
                    }
                    <Layout className="content-inner" justify="center" align="center" direction="column">
                        <LayoutItem align="center" className="intro">
                            <Text as="h2" variant="order4" spaceStackEnd={mq.mobi ? null:'xs'}>
                                {locale.the[lang]} Audi {car.slug === 'q8-e-tron' ? car.name:car.carline.name} {car.year}.
                            </Text>
                            <Text as="p" variant="copy1" spaceStackEnd={mq.mobi ? 's':'m'}>
                               {locale.makeadeposit[lang]}
                            </Text>
                        </LayoutItem>

                        <Layout className="summary-table" direction="column" spacing={[mq.mid ? 's':'l',null,null]}>
                            <Layout spaceStackEnd="s">
                                <Text as="p" variant="copy1" weight="bold">{locale.vehicledetails[lang]}</Text>
                            </Layout>
                            <Divider/>
                            <Layout justify="between" spaceStackStart="m" spaceStackEnd="s">
                                <Text as="p" variant="copy2" weight="bold">{locale.model[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right">Audi {car.slug === 'q8-e-tron' ? car.name:car.carline.name} {car.year}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="xs">
                                <Text as="p" variant="copy2" weight="bold">{locale.exterior[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right">{car.catalog.features.data[1].name}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="xs">
                                <Text as="p" variant="copy2" weight="bold">{locale.trim[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right">{engine.fullName}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="xs">
                                <Text as="p" variant="copy2" weight="bold">{locale.transmission[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right">{engine.gearType}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="xs">
                                <Text as="p" variant="copy2" weight="bold">{locale.gear[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right">{engine.gearName}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="xs">
                                <Text as="p" variant="copy2" weight="bold">{locale.interior[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right pad-right">{car.catalog.features.data[2].name}</Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="m">
                                <Text as="p" variant="copy2" weight="bold">{locale.wheels[lang]}:</Text>
                                <Text as="p" variant="copy2" className="text-right pad-right">{car.catalog.features.data[0].name}</Text>
                            </Layout>
                            <Divider/>
                        </Layout>
                        <Layout className="summary-table" direction="column" spaceStackStart="l">
                            <Layout spaceStackEnd="s">
                                <Text as="p" variant="copy1" weight="bold">{locale.contactdetails[lang]}:</Text>
                            </Layout>
                            <form>
                                <Layout className="form" direction="column" spacing={[null,null,'s']}>
                                    <Layout className="form-row" wrap="wrap">
                                        <LayoutItem basis="100%" spaceStackEnd="m">
                                            <TextField
                                                inputMode="email"
                                                type="email"
                                                inputId="email"
                                                label={locale.email[lang]}
                                                value={form.email}
                                                required
                                                invalid={emailErr ? true:false}
                                                onChange={onChange}
                                                />
                                        </LayoutItem>
                                        <LayoutItem basis="100%">
                                            <FormGroup>
                                                <Layout className="code-wrapper">
                                                    <LayoutItem basis="75px" shrink="1" className="code">
                                                        <TextField
                                                            inputMode="text"
                                                            type="text"
                                                            inputId="code"
                                                            label={locale.code[lang]}
                                                            value={`+${region.code}-`}
                                                            spaceInlineStart={null}
                                                            disabled
                                                            required
                                                            />
                                                    </LayoutItem>
                                                    <LayoutItem basis="100%">
                                                        <TextField
                                                            inputMode="tel"
                                                            type="tel"
                                                            pattern="[0-9.]*"
                                                            minLength={region.phoneLength.min}
                                                            maxLength={region.phoneLength.max}
                                                            inputId="phone"
                                                            label={locale.phone[lang]}
                                                            value={form.phone}
                                                            required
                                                            invalid={phoneErr ? true:false}
                                                            onChange={(e)=>{
                                                                let val = e.currentTarget.value;
                                                                if(!isNaN(val)){
                                                                    onChange(e)
                                                                }
                                                            }}
                                                            />
                                                    </LayoutItem>
                                                </Layout>
                                            </FormGroup>
                                        </LayoutItem>
                                    </Layout>
                                </Layout>
                            </form>
                        </Layout>
                        <Divider/>
                        <Layout className="summary-table" direction="column" spaceStackStart="l">
                            <Layout spaceStackEnd="s">
                                <Text as="p" variant="copy1" weight="bold">{locale.ordersummary[lang]}:</Text>
                            </Layout>
                            <Divider/>
                            <Layout justify="between" spaceStackStart="m" spaceStackEnd="s">
                                <Text as="p" variant="copy2" weight="bold">{locale.startingprice[lang]}:</Text>
                                <Text as="p" variant="copy2" weight="bold" className="text-right">
                                    {
                                        !car.price.full ? 
                                        locale.onrequest[lang]
                                        :
                                        `${region[lang].currency} ${parseInt(car.price.full).toLocaleString()}`
                                    }
                                </Text>
                            </Layout>
                            <Layout justify="between" spaceStackEnd="m">
                                <Text as="p" variant="copy2" weight="bold">{locale.reserveprice[lang]}:</Text>
                                <Text as="p" variant="copy2" weight="bold" className="text-right">
                                    {region[lang].currency} {parseInt(car.price.deposit).toLocaleString()}
                                </Text>
                            </Layout>
                            <Divider/>
                            <Layout justify="between" spaceStackStart="m" spaceStackEnd="l" align="center">
                                <Text as="p" variant="copy1" weight="bold">{locale.requiredtoday[lang]}</Text>
                                <Text as="p" variant="copy1" weight="bold" className="text-right">
                                    {region[lang].currency} {parseInt(car.price.deposit).toLocaleString()}
                                </Text>
                            </Layout>
                            <Layout spaceStackStart="xs" spaceStackEnd="m">
                                <Checkbox 
                                    inputId="consent" 
                                    name="consent" 
                                    value={form.consent}
                                    required
                                    invalid={consentErr ? true:false}
                                    onChange={onChange}
                                    >
                                    {locale.agreeterms[lang]}
                                </Checkbox>
                            </Layout>
                            <Layout className="checkout-btns" direction="row" align="start" wrap="wrap">
                                <PayBtn 
                                    region={region.name} 
                                    text={`${car.preorder ? locale.preorder[lang]:locale.order[lang]} (${region[lang].currency} ${parseInt(car.price.deposit).toLocaleString()})`} 
                                    busyText={locale.processing[lang]} 
                                    settings={settings} 
                                    payload={payload}
                                    disabled={!ready}/>
                            </Layout>
                            {
                                (emailErr || phoneErr || consentErr) &&
                                <Layout spaceStackStart="m">
                                    <Text variant="copy2" spaceInlineEnd="xs" className="error-text">
                                        {
                                            emailErr ? locale.errors.email[lang]
                                            : phoneErr ? locale.errors.phone[lang]
                                            : consentErr ? locale.errors.consent[lang]
                                            :false
                                        }
                                    </Text>
                                </Layout>
                            }
                            <Layout spaceStackStart="m">
                                <Button variant="text" size="small" onClick={()=>setTerms(true)} spaceInlineEnd="xs">{locale.terms[lang]}</Button>
                                <Button variant="text" size="small" onClick={()=>setHow(true)}>{locale.howitworks[lang]}</Button>
                            </Layout>
                        </Layout>
                    </Layout>
                </LayoutItem>
                <LayoutItem className="media" basis={mq.mid ? '100%':'0%'} grow="1">
                    {
                        mq.mid &&
                        <Layout className="checkout-actions" justify="between" align="center">
                            <Layout align="center" className="drag-info">
                                <Text variant="copy2" weight="bold" spaceInlineEnd="s">
                                    { mq.tablet ? locale.swipe[lang]:locale.drag[lang] }
                                </Text>
                                <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M18.2,8.2l4.5,4.5l-4.5,4.5 M0.2,12.8h22.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </Layout>
                            <div className="close" onClick={closeCheckout}>
                                <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </div>
                        </Layout>
                    }
                    <Layout className="carousel">
                        <LayoutItem className={`item ${active === 0 ? 'active':''}`} shrink="0" grow="0">
                            <picture>
                                <source type="image/webp" srcSet={`${car.media.renderImages.n4c01}?mimetype=image/webp`}/>
                                <img src={car.media.renderImages.n4c01} alt=""/>
                            </picture>
                        </LayoutItem>
                        <LayoutItem className={`item ${active === 1 ? 'active':''}`} shrink="0" grow="0">
                            <picture>
                                <source type="image/webp" srcSet={`${car.media.renderImages.n4c12}?mimetype=image/webp`}/>
                                <img src={car.media.renderImages.n4c12} alt=""/>
                            </picture>
                        </LayoutItem>
                        <LayoutItem className={`item ${active === 2 ? 'active':''}`} shrink="0" grow="0">
                            <picture>
                                <source type="image/webp" srcSet={`${car.media.renderImages.n4c14}?mimetype=image/webp`}/>
                                <img src={car.media.renderImages.n4c14} alt=""/>
                            </picture>
                        </LayoutItem>
                    </Layout>
                </LayoutItem>
            </Layout>
        </div>
    )
}

function Model({settings,car,currency,setTerms,setHow,openCheckout,sendLoaded,sendFailure}){
    
    const [show,setShow] = useState(false);

    const { lang } = useArabic();

    const carSlug = {...car,slug:car.model};

    const tempRm = car.model === 'q8-sportback-e-tron' ? car.prcodes.filter(code => code !== '3NT' && code !== 'PIC' && code !== 'YEB'):car.prcodes.filter(code => code !== '3NT' && code !== 'PIC' && code !== 'YEB' && !code.startsWith("WE"));

    const queryObject = {
        configuredCarIdentifier: {
            brand: "A",
            country: "me",
            language: "en",
            exteriorColor:car.exterior,
            interiorColor:car.interior,
            equipmentOptions:tempRm,
            model:{
                code: car.code,
                version: 0,
                year:parseInt(car.year)
            }
        },
        featuresFilterInput: {
            filterByFeatureGroup: [
                "EXTERIOR_COLORS",
                "INTERIOR_TILES",
                "RIMS"
            ],
            filterByFeatureState: "selected"
        }   
    }

    const { data, error, refetch } = useQuery(Query, {
        variables: queryObject,
        fetchPolicy:'cache-first'
    });

    useEffect(() => {
        if(data){
            sendLoaded();
        }
    }, [data])

    const [version,setVersion] = useState(1);
    let v = 0;
    useEffect(() => {
        if(error){
            if(error.message.indexOf('MODEL_NOT_FOUND') > -1){
                const query = {...queryObject};
                query.configuredCarIdentifier.model.version = version;
                setVersion(2);
                refetch(query)
            }else {
                v++;
                sendFailure();
            }
        }
    }, [error]);
    
    return data ?
        <LayoutItem shrink="0" className={`model ${show ? 'loaded':''} ${car.reserved ? 'reserved':''}`}>
            <div className="thumb" onClick={()=>{!car.reserved ? openCheckout(Object.assign(carSlug, data.configuredCar)):null}}>
                <picture>
                    <source type="image/webp" srcSet={`${data.configuredCar.media.renderImages.n4c01}?mimetype=image/webp`}/>
                    <img src={data.configuredCar.media.renderImages.n4c01} alt="" onLoad={()=>setShow(true)}/>
                </picture>
                <Tag className={`badge ${car.reserved ? 'reserved':''}`} label={car.reserved ? 'Reserved':'Available'}/>
                {/* {
                    settings.offline && <Tag className="badge vin" label={car.vin}/>
                } */}
            </div>
            <div className="detail">
                <Text variant="order4" spacing={['s',null,'xxs']}>Audi {car.model === 'q8-e-tron' ? car.name:data.configuredCar.carline.name}</Text>
                <Text variant="copy1" spaceStackEnd="xs">{data.configuredCar.model.name}</Text>
                <Divider/>
                <Layout justify="between" spaceStackStart="xs">
                    <Text variant="copy2">
                        {locale.exterior[lang]}:
                    </Text>
                    <Text variant="copy2">
                        {data.configuredCar.catalog.features.data[1].name}
                    </Text>
                </Layout>
                <Layout justify="between" spaceStackStart="xxs">
                    <Text variant="copy2">
                        {locale.year[lang]}:
                    </Text>
                    <Text variant="copy2">
                        2023
                    </Text>
                </Layout>
                <Layout justify="between" spaceStackStart="xxs" spaceStackEnd="xs">
                    <Text variant="copy2">
                        {locale.startingprice[lang]}:
                    </Text>
                    <Text variant="copy2" weight="bold">
                        {!car.price.full ? locale.onrequest[lang]:`${parseInt(car.price.full).toLocaleString()} ${currency}`}
                    </Text>
                </Layout>
                <Divider/>
                <Layout justify="between" spaceStackStart="s">
                    <Text variant="copy1">
                        {locale.requiredtoday[lang]}
                    </Text>
                    <Text variant="copy1" weight="bold">
                        {parseInt(car.price.deposit).toLocaleString()} {currency}
                    </Text>
                </Layout>
                <Layout className="btn-group" spaceStackStart="m">
                    <Button variant="primary" stretch onClick={()=>openCheckout(Object.assign(carSlug, data.configuredCar))} disabled={car.reserved ? true:false}>{car.preorder ? locale.preorder[lang]:locale.order[lang]}</Button>
                </Layout>
                <Layout spaceStackStart="m">
                    <Text variant="copy3" spaceInlineEnd="xs"><Button variant="text" size="small" onClick={()=>setTerms(true)}>{locale.terms[lang]}</Button></Text>
                    <Text variant="copy3"><Button variant="text" size="small" onClick={()=>setHow(true)}>{locale.howitworks[lang]}</Button></Text>
                </Layout>
            </div>
        </LayoutItem>
    : false
}

const Stock = ({region,list,settings}) => {

    const oneGraphClient = createOneGraphClient({
        endpoint: 'https://onegraph.audi.com/graphql',
        clientName: 'stock-orders',
        clientVersion: '0.0.1',
    });

    const { arabic, lang } = useArabic();
    const mq = useBreaks();

    const [loaded,setLoaded] = useState(false);
    const [count,setCount] = useState(0);
    const [errors,setErrors] = useState(0);
    const [failed,setFailed] = useState(false);
    const sendLoaded = () => {
        if(!loaded){
            setLoaded(true);
        }
        setCount(count+1);
    }
    const sendFailure = () => {
        setErrors(errors+1);
    }

    useEffect(() => {
        if(list){
            if(errors === list.length){
                setFailed(true);
            }
        }
    }, [errors,list])

    // vh
    useEffect(() => {
        const vUnit = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        vUnit();
        window.addEventListener('resize', vUnit)
    }, [])

    // sort list 
    const [sort,setSort] = useState(false);
    useEffect(() => {
        if(list && list.length > 0){
            setSort(list.sort((a, b) => (!a.reserved < !b.reserved) ? 1 : -1));
        }
    }, [list])

    // slider drag / overflow
    const [overflow,setOverflow] = useState(false);

    const slider = useCallback(node => {
        if (node !== null) {
            let down = false;
            let start = false;
            let scroll = false;

            const mouseDown = (e) => {
                if(!e.target.closest('.thumb') && !e.target.closest('.btn-group') && !e.target.closest('button')){
                    down = true;
                    start = e.pageX - node.offsetLeft;
                    scroll = node.scrollLeft;
                    node.classList.add('active');
                }
            }
            const mouseUp = (e) => {
                down = false;
                node.classList.remove('active');
            }
            const mouseMove = (e) => {
                e.preventDefault();
                if(down){
                    const x = e.pageX - node.offsetLeft;
                    const walk = (x - start) * 1;
                    node.scrollLeft = scroll - walk;
                }
            }
            const hasOverflow = () => {
                setOverflow(node.scrollWidth > node.offsetWidth ? true:false)
            }
            const callback = (mutationList) => {
                for(const mutation of mutationList){
                    if(mutation.type === 'childList'){
                        hasOverflow();
                    }
                }
            };

            node.addEventListener('mousedown', mouseDown);
            node.addEventListener('mouseleave', mouseUp);
            node.addEventListener('mouseup', mouseUp);
            node.addEventListener('mousemove', mouseMove);
            window.addEventListener('resize',hasOverflow);
            
            const observer = new MutationObserver(callback);
            observer.observe(node, { attributes: false, childList: true, subtree: true });

            return () => {
                node.removeEventListener('mousedown', mouseDown);
                node.removeEventListener('mouseleave', mouseUp);
                node.removeEventListener('mouseup', mouseUp);
                node.removeEventListener('mousemove', mouseMove);
                window.removeEventListener('resize',hasOverflow)
                observer.disconnect();
            }
        }
    }, []);

    // modal
    const [terms, setTerms] = useState(false);
    const [how, setHow] = useState(false);

    // checkout
    const [checkout,setCheckout] = useState(null);
    const [open,setOpen] = useState(false);

    const openCheckout = (car) => {
        setOpen(true);
        setCheckout(car);

        // freeze scroll
        document.querySelector('html').classList.add('freeze-scroll');
    }

    const closeCheckout = () => {
        setOpen(false);
        setTimeout(()=>{
            setCheckout(null);
            // freeze scroll
            document.querySelector('html').classList.remove('freeze-scroll');
        },400);
    }
    return sort ? 
        <OneGraphProvider client={oneGraphClient}>
            <Styled as={Layout} wrap="wrap" direction="column" overflow={overflow} arabic={arabic}> 
                {
                    loaded &&
                    <Layout className="info" justify="between" spaceStackEnd="m">
                        <LayoutItem>
                            {
                                arabic && <Text variant="copy1" weight="bold"> 
                                    {sort.length} {sort.length === 1 ? 'سيارة واحدة متوفرة في المخزون':'سيارات متوفرة في المخزون'}
                                </Text>
                            }
                            {
                                !arabic && <Text variant="copy1" weight="bold"> 
                                    {count} {count === 1 ? 'car':'cars'} available
                                </Text>
                            }
                        </LayoutItem>
                        {
                            overflow &&
                            <LayoutItem>
                                <Layout align="center" className="drag-swipe">
                                    <Text variant="copy2" weight="bold" spaceInlineEnd="s">
                                        { mq.tablet ? locale.swipe[lang]:locale.drag[lang] }
                                    </Text>
                                    <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M18.2,8.2l4.5,4.5l-4.5,4.5 M0.2,12.8h22.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                                </Layout>
                            </LayoutItem>
                        }
                    </Layout>
                }
                <Layout className={`list ${!loaded ? 'hide':''}`} ref={slider}>
                    {
                        sort &&
                        sort.map(car => {
                            return <Model key={car.vin} settings={settings} car={car} currency={regions[region][lang].currency} setTerms={setTerms} setHow={setHow} openCheckout={openCheckout} sendLoaded={sendLoaded} sendFailure={sendFailure}/>
                        })
                    }
                </Layout>
                { (!loaded && !failed) && <Layout className="loader" justify="center" align="center"><Loader monochrome/></Layout>}

                {
                    failed && 
                    <Layout className="loader" justify="center" align="center" direction="column">
                        <Text variant="order4" weight="bold" spaceStackEnd="xs">{locale.nocars.title[lang]}</Text>
                        <Text variant="copy1" spaceStackEnd="l">{locale.nocars.body[lang]}</Text>
                        <LayoutItem align="center">
                            <Layout>
                                <Button variant="primary" size="small" href={`${regions[region].url}/tools/form/general-enquiry.html`} spaceInlineEnd="s">{locale.nocars.enquire[lang]}</Button>
                                <Button variant="secondary" size="small" href={`${regions[region].url}/new-cars.html`}>{locale.nocars.view[lang]}</Button>
                            </Layout>
                        </LayoutItem>
                    </Layout>
                }

                <Modal open={terms} closeOnDocumentClick onClose={()=>setTerms(false)}>
                    <AudiPlatformProvider>
                    <div className={`modal ${arabic ? 'arabic':''}`} dir={arabic ? 'rtl':'ltr'}>
                        <Layout className="inner" direction="column">
                            <button className="close" onClick={()=>setTerms(false)}>
                                <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </button>
                            <Layout className="body" direction="column">
                                <Text as="h1" variant="order4">{locale.tnc.privacy[lang]}</Text>
                                <Text as="p" variant="copy1">{locale.fine[lang]}</Text>
                                <Text as="h1" variant="order4" spaceStackStart="m">{locale.tnc.vehicletitle[lang]}</Text>
                                <List variant="bullet">
                                {
                                    locale.tnc.vehiclelist[lang].map((item,i)=>(
                                        <ListItem key={i}>{item}</ListItem>
                                    ))
                                }
                                </List>
                                <Text as="h1" variant="order4" spaceStackStart="m">{locale.tnc.finance[lang]}</Text>
                                <List variant="bullet">
                                    {
                                    regions[region][lang].terms.map((term,i) => (
                                        <ListItem key={i} className={`${term.indexOf('<b>') > -1 ? 'no-bullet':''}`}><span dangerouslySetInnerHTML={{__html: term}}></span></ListItem>
                                    ))
                                    }
                                </List>
                            </Layout>
                        </Layout>
                    </div>
                    </AudiPlatformProvider>
                </Modal>

                <Modal open={how} closeOnDocumentClick onClose={()=>setHow(false)}>
                    <AudiPlatformProvider>
                    <div className={`modal ${arabic ? 'arabic':''}`} dir={arabic ? 'rtl':'ltr'}>
                        <Layout className="inner" direction="column">
                            <button className="close" onClick={()=>setHow(false)}>
                                <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </button>
                            <Layout className="body" direction="column">
                                <Text as="h1" variant="order4">{locale.how.title[lang]}</Text>
                                <Text as="p" variant="copy1" spaceStackStart="xs" spaceStackEnd="xs">
                                    {locale.how.body[lang]}
                                </Text>
                                <List variant="bullet">
                                {
                                    locale.how.list[lang].map((item,i)=>(
                                        <ListItem key={i}>{item}</ListItem>
                                    ))
                                }
                                </List>
                            </Layout>
                        </Layout>
                    </div>
                    </AudiPlatformProvider>
                </Modal>

                <Drawer
                    open={open}
                    onClose={closeCheckout}
                    duration={400}
                    size="100vh"
                    direction="bottom"
                    className="drawer"
                    >
                    <Checkout settings={settings} car={checkout} region={regions[region]} setTerms={setTerms} setHow={setHow} closeCheckout={closeCheckout}/>
                </Drawer>
            </Styled>
        </OneGraphProvider>
    :false
}

export default Stock;