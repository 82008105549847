import React from 'react';

// context
import { Config } from '../context';

export default function Provider({value,children}){
    
    return (
        <Config.Provider value={value}>
            { children }
        </Config.Provider>
    )
}