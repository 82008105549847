import React, { useState, useContext, useEffect, useRef } from 'react';

import { Config, Choice } from '../../context';

import useRegion from '../../hooks/useRegion';
import useBreaks from '../../hooks/useBreaks';
import useArabic from '../../hooks/useArabic';

import { Layout, LayoutItem, Text, Button } from '@audi/audi-ui-react';

import Styled from './styles';

import locale from '../../json/locale.json';

export default function Module({single}){

    const { department } = useContext(Config);
    const { choice } = useContext(Choice);
    
    const region = useRegion();
    const { lang } = useArabic();
    const mq = useBreaks();

    const [show,setShow] = useState(false); 

    const thanks = useRef();
    useEffect(() => {
        if(thanks.current){
            thanks.current.scrollIntoView({block:'start'});
        }
    }, [thanks])

    useEffect(() => {
        if(window.dataLayer){
            window.dataLayer.push({
                event: 'ramadan', 
                type:'form', 
                value: "sent", 
                model:choice.slug
            })
        }
    }, [])

	// render
	return (
		<Styled as={LayoutItem} loaded={show} ref={thanks} single={single} generic={choice.generic}>
            <div className="media">
                    {
                        department === 'new' ?
                        (choice.generic || single) ? 
                        <picture>
                            <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.avif`}/>
                            <img src={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan`} onLoad={()=>setShow(true)}/>
                        </picture>
                        :
                        <picture>
                            <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/models/${choice.slug}/thanks/${mq.mid ? 'mobi':'banner'}.avif`}/>
                            <img src={`${process.env.PUBLIC_URL}/assets/models/${choice.slug}/thanks/${mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Model on Ramadan`} onLoad={()=>setShow(true)}/>
                        </picture>
                        :
                        <picture>
                            <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.avif`}/>
                            <img src={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan`} onLoad={()=>setShow(true)}/>
                        </picture>
                    }
            </div>
            <Layout className="content">
                <Layout direction="column" justify="start" align="center" spacing={['xxxl','m',single ? 'xxxl':null]}>
                    <Text as="h1" variant={mq.tablet ? 'order3':'order1'}>
                        <b>{locale.thanks.title[lang]}</b> 
                        {department === 'new' && <><br/>{locale.words.the[lang]} Audi {choice.name} 2023.</>}
                    </Text> 
                    <Text as="h1" variant={mq.tablet ? 'order4':'order2'} spacing={['m',null]}>
                        {locale.thanks.sub[lang]}
                    </Text> 
                    <Text as="h2" variant={mq.tablet ? 'copy1':'order4'} spacing={[mq.tablet ? 's':'s',null,'l']}>{locale.thanks.body[lang]}</Text>
                    {(department === 'new' && !single && choice.link) && <Button variant="text" size="medium" href={`${region.data[lang].link}models/${choice.link[lang]}?utm_source=ramadan-thanks&utm_campaign=ramadan-2023`}>{locale.words.discover[lang]} Audi {choice.name}</Button>}
                </Layout>
            </Layout>
		</Styled>
	);
}
