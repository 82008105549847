export default function sendGoogleEvent(category, action, label, isInteraction) {
    return new Promise(resolve => {
        function send() {
            
            window.ga('create', 'GTM-5R7QKBG', 'auto');
            
            var eventObject = {
                hitType: 'event',
                eventCategory: category,
                eventAction: action,
                nonInteraction: undefined === isInteraction ? false : true,
                hitCallback: function() {
                    resolve();
                }
            };
            if(undefined !== label){
                if ('string' === typeof label) {
                    eventObject.eventLabel = label;
                }
                if ('number' === typeof label) {
                    eventObject.eventValue = label;
                }
            }
            window.ga('send', eventObject);
        }
        if ('function' === typeof window.ga) {
            send();
        } else {
            var tick = setInterval(function(){
                if ('function' === typeof window.ga) {
                    clearInterval(tick);
                    send();
                }
            }, 100);
        }
    });
};