import gql from 'graphql-tag';

const CAR = gql`
    query configuredCar($configuredCarIdentifier: ConfiguredCarInput!, $featuresFilterInput: FeaturesFilterInput) {
        configuredCar(configuredCarIdentifier: $configuredCarIdentifier) {
            media {
                renderImages {
                    n4c01
                    n4c12
                    n4c14
                    n4c02
                }
            }
            carline {
                name
            }
            model {
                name
            }
            catalog {
                models {
                    data {
                        gearType
                        gearName
                        fullName
                        engineName
                        driveName
                        status {
                            selected
                        }
                    }
                }
                features {
                    data(featuresFilterInput: $featuresFilterInput) {
                        name
                    }
                }
            }
        }
    }
`;
export default CAR;