import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Config } from '../context';

export default function useArabic(){
	
    // context
    const config = useContext(Config);

    const router = useParams();

	let href = window.location.href;

    if(href.indexOf('l=ar') > -1 || href.indexOf('/ar/') > -1 || href.indexOf('ar.audi') > -1 || config.arabic || router.lang === 'ar'){
        return { 
            arabic: true, 
            lang: 'ar'
        };
    }

    return { 
        arabic: false, 
        lang: 'en'
    };
}
