import React from 'react';

import { createOneGraphClient, OneGraphProvider } from '@volkswagen-onehub/onegraph-client';

const oneGraphClient = createOneGraphClient({
    endpoint: 'https://onegraph.audi.com/graphql',
    clientName: 'stock-orders',
    clientVersion: '0.0.1',
});

export default function Provider({children}){
    return (
        <OneGraphProvider client={oneGraphClient}>
            {children}
        </OneGraphProvider>
    )
}