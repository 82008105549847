// Apple Pay?
// Style pay pages

import React, { useState } from 'react';

import { Button } from '@audi/audi-ui-react';

import usePayment from './hooks/usePayment';

const PayBtn = ({region,text,busyText,className,settings,payload,disabled}) => {

    const [busy,setBusy] = useState(false);

    const { processPayment } = usePayment(payload,settings);
    
    if(!region){
        return <Button variant="primary" disabled>No Region</Button>
    }
    if(!text){
        return <Button variant="primary" disabled>No Text</Button>
    }
    if(!settings){
        return <Button variant="primary" disabled>No Settings</Button>
    }
    if(!payload){
        return <Button variant="primary" disabled>No Payload</Button>
    }
    
    return <Button variant="primary" className={className} disabled={(disabled || busy) && true} onClick={()=>{
        setBusy(true);
        processPayment(region);
        if(window.dataLayer){
            window.dataLayer.push({
                event: 'ecommerce', 
                type:'order', 
                value: "clicked"
            })
        }
    }}>{busy ? busyText:text}</Button>
}

export default PayBtn;