import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { AudiPlatformProvider, ThemeProvider, audiDarkTheme, Layout, Loader } from '@audi/audi-ui-react';

export default function Page(){ 

    const navigate = useNavigate();

    useEffect(() => {
        const getCountry = async () => {
            const res = await axios('https://rest.audimiddleeast.com/ip/ramadan.php');
            if(res.data.status === 'success'){
                if(res.data.countryCode === 'AE'){
                    if(res.data.region === "AZ"){
                        navigate(`/abudhabi/en`);
                    }else {
                        navigate(`/dubai/en`);
                    }
                }else if(res.data.countryCode === 'BH'){
                    navigate(`/bahrain/en`);
                }else if(res.data.countryCode === 'KW'){
                    navigate(`/kuwait/en`);
                }else if(res.data.countryCode === 'SA'){
                    navigate(`/saudiarabia/en`);
                }else if(res.data.countryCode === 'OM'){
                    navigate(`/oman/en`);
                }else if(res.data.countryCode === 'QA'){
                    navigate(`/qatar/en`);
                }else if(res.data.countryCode === 'JO'){
                    navigate(`/jordan/en`);
                }else if(res.data.countryCode === 'LB'){
                    navigate(`/lebanon/en`);
                }else {
                    window.location.href = 'https://www.audi-me.com/me/web/meen.html?utm_source=ramadan-redirect&utm_campaign=ramadan-2023';
                }
            }
        }
        getCountry();
    }, [navigate])

	return (
		<AudiPlatformProvider>
			<ThemeProvider theme={audiDarkTheme}>
                <Layout className="error" justify="center" align="center">
                    <Loader monochrome/>
                </Layout>
			</ThemeProvider>
		</AudiPlatformProvider>
	)
}