import styled from 'styled-components';

const Styled = styled.div`
    overflow: hidden;
    position: relative;
    background:black;
    width:100%;
    .media {
        z-index:0;
        overflow: hidden;
        width: 100%;
        height: 100vh; 
        height: calc(var(--vh, 1vh) * 100);
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            opacity:${props => props.loaded ? 1:0};
            display:block;
            transition:.4s opacity cubic-bezier(0.850, 0.020, 0.500, 1.000);
        }
    }
    .content {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background: -moz-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

        h1,h2 {
            text-align:center;
        }

        h1 {
            line-height:1.4;
        }

        h2 {
            max-width:700px;
        }

    }

    ${props => props.single && `
        .media {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
        .content {
            position:relative;
            height:auto;
            background:rgba(0,0,0,.7);
        }
    `}

    ${props => props.generic && `
        .content {
            background:rgba(0,0,0,.7);
        }
    `}

`;

export default Styled;
