import React, { useState, useContext, useRef, useEffect } from 'react';

import { Choice } from '../../context';

import useArabic from '../../hooks/useArabic';
import useOffer from '../../hooks/useOffer';
import useBreaks from '../../hooks/useBreaks';
import useRegion from '../../hooks/useRegion';
import useStock from '../../hooks/useStock';

import sendGoogleEvent from '../../providers/helpers/events';

import { useInView } from 'react-intersection-observer';

import { Layout, LayoutItem, Text, Button, ThemeProvider, audiLightTheme } from '@audi/audi-ui-react';

import Stock from 'stock';

import Styled from './styles';

import parse from 'html-react-parser';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

import locale from '../../json/locale.json';

function Model({model,isMore,price}){

    const { setChoice, clearChoice } = useContext(Choice);

    const region = useRegion();

    const { lang } = useArabic();
    const { offer, otherModels } = useOffer();
    const mq = useBreaks();

    const settings = {
        production:true,
        offline:false,
        callback:`https://ramadan.audimiddleeast.com/transaction/success/${lang}`
    };
        
    const stock = useStock(region.slug,!isMore ? [model.value]:otherModels);

    const [show,setShow] = useState(false); 

    const modelOffer = !isMore ? model.offer ? model.offer[lang]:offer ? offer:false:false;

    const scrollForm = (slug,name,tag,price,generic,en,ar) => {
        if(!isMore){
            setChoice({
                slug:slug,
                name:name,
                tag:tag,
                single:false,
                price:price ? price[lang]:false,
                generic:generic ? true:false,
                link:{
                    en:en,
                    ar:ar
                }
            });
            sendGoogleEvent('Ramadan 2023', `Click - Enquire now`, `Model - ${name}`);  
        }else {
            clearChoice();
            sendGoogleEvent('Ramadan 2023', `Click - Enquire now`, 'Model - Other models');  
        }
        document.querySelector('.form').scrollIntoView({behavior:'smooth', block:'start'});
    }
    
    // drawer
    const [isOpen, setIsOpen] = useState(false)
    const openDrawer = (model) => {
        setIsOpen((prevState) => !prevState);
        sendGoogleEvent('Ramadan 2023', `Click - Open Stock`, `Model - ${!model ? 'Other models':model}`);  
    }
    const closeDrawer = (model) => {
        setIsOpen((prevState) => !prevState);
        sendGoogleEvent('Ramadan 2023', `Click - Close stock`, `Model - ${!model ? 'Other models':model}`);  
    }

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    });
    
    useEffect(() => {
        if(inView){
            sendGoogleEvent('Ramadan 2023', `Impression - Model`, `Model - ${!model ? 'Other models':model.name}`);  
        }
    }, [inView,model])

    // open stock
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if(model?.value && params.get('order') === model.value){
            openDrawer(model.value);
        }
    }, []);
    

    return (
        <Styled as={LayoutItem} className={`model ${show ? 'loaded':''}`} spaceStackStart={null} basis={model?.small && !mq.mid ? 'calc(50% - 6px)':'100%'} small={model?.small ? true:false} grow="1" shrink="1" ref={ref}>
            <div className="media">
                {
                isMore ? 
                region.data.excludeRange ? 
                    <picture>
                        <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/generic/${model?.small || mq.mid ? 'mobi':'banner'}.avif`}/>
                        <img src={`${process.env.PUBLIC_URL}/assets/generic/${model?.small || mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan Banner`} onLoad={()=>setShow(true)}/>
                    </picture>
                :
                    <picture>
                        <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/range/${region.slug}/${model?.small || mq.mid ? 'mobi':'banner'}.avif`}/>
                        <img src={`${process.env.PUBLIC_URL}/assets/range/${region.slug}/${model?.small || mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan Banner`} onLoad={()=>setShow(true)}/>
                    </picture>
                :
                <picture>
                    <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/models/${model.value}/${model?.small || mq.mid ? 'mobi':'banner'}.avif`}/>
                    <img src={`${process.env.PUBLIC_URL}/assets/models/${model.value}/${model?.small || mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan Banner`} onLoad={()=>setShow(true)}/>
                </picture>
                }
            </div>
            <Layout className="content">
                <Layout direction="column" justify="between" align="center" spacing={['xl',mq.tablet ? 'm':'xl','l']}>
                    <LayoutItem align="center">
                        <Text as="h1" variant={mq.mid ? 'order2':'order1'}>
                            { !isMore ? 
                                (price && modelOffer) ?
                                <span><b>{locale.words.the[lang]}Audi {model.name}{lang === 'ar' ? '':'.'} </b><br/>{model.price[lang]}</span>
                                :
                                <span><b>{locale.words.the[lang]}Audi {model.name}{lang === 'ar' ? '':'.'} </b><br/>{locale.tag.body[lang]}</span>
                            :
                                <span><b>{locale.tag.generosity[lang]}</b> <br/>{locale.tag.body[lang]}</span>}
                        </Text> 
                        {
                            isMore && 
                            <Text as="h2" variant={mq.mid ? 'copy1':'order4'} spacing={['xxs',null,'m']}>
                               { region.data.sayallmodels ? locale.all[lang]:locale.also[lang]}
                            </Text>
                        }
                        <Layout className="offers" justify="center" align="start" spacing={[mq.mid ? 'm':'xl',null,null]} wrap="wrap">
                        {
                            (!isMore && modelOffer) &&
                            modelOffer.map((offer,i) => (
                                <div className="offer" key={i}>
                                    <Layout className="inner">
                                        <LayoutItem>
                                            <Text as="h2" variant="order1">{parse(offer.unit)}</Text>
                                        </LayoutItem>
                                        <LayoutItem align="start">
                                            <Text as="p" variant="order1">{parse(offer.body)}</Text>
                                        </LayoutItem>
                                    </Layout>
                                    { (i+1) < modelOffer.length && <div className="divider"/> }
                                </div>
                            ))
                        }
                        {
                            (price && !modelOffer) &&
                            <div className="offer">
                                <Text as="h3" variant="order1">{model.price[lang]}</Text>
                            </div>
                        }
                        {
                            (isMore && !region.data.entirefleet) &&
                            otherModels.map((model,i) => (
                                <div className="offer" key={i}>
                                    { i > 0 && <div className="divider"/> }
                                    <Text as="h3" variant="order1">{model.name}</Text>
                                </div>
                            ))
                        }
                        </Layout>
                    </LayoutItem>
                    <LayoutItem align="center">
                        <Layout className="btn-group">
                            <Button variant="primary" size="medium" className="red" onClick={()=>isMore ? scrollForm():scrollForm(model.value,model.name,model.tag,model.price,model.generic,model.link.en,model.link.ar)}>{locale.cta.enquire[lang]}</Button>
                            { 
                                (region.ecommerce && stock.length > 0 && !model.blockpay) ? 
                                <Button variant="primary" size="medium" onClick={()=>isMore ? openDrawer():openDrawer(model.name)} loading>{locale.cta.order[lang]}</Button> 
                                : !isMore && 
                                <Button variant="primary" size="medium" href={`${region.data[lang].link}models/${model.link[lang]}?utm_source=ramadan-model&utm_campaign=ramadan-2023`}>{locale.cta.explore[lang]}</Button> 
                            }
                        </Layout>
                    </LayoutItem>
                </Layout>
            </Layout>
            {
                (region.ecommerce && stock.length > 0) &&
                <Drawer
                    open={isOpen}
                    onClose={()=>isMore ? closeDrawer():closeDrawer(model.name)}
                    duration={400}
                    size="auto"
                    direction="bottom"
                    className="drawer"
                    >
                    <ThemeProvider theme={audiLightTheme}>
                    <Layout wrap="wrap" className="stock" direction="column">
                        <Layout justify="between" className="actions">
                            <button className="close" onClick={()=>isMore ? closeDrawer():closeDrawer(model.name)}>
                                <svg width="48" height="48" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/></svg>
                            </button>
                        </Layout>
                        <Stock list={stock} region={region.slug} settings={settings}/>
                    </Layout>
                    </ThemeProvider>
                </Drawer>
            }
        </Styled>
    )
}

export default function Module(){

    const { models, otherModels } = useOffer();
	return (
        models && 
        <>
        {
            models.map((model,i) => (
                <Model key={i} model={model} price={model.hasOwnProperty('price') ?? true}/>
            ))
        }
        {
            otherModels.length > 0 && <Model isMore/>
        }
        </>
	);
}

