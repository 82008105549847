// test forms
// oman disclaimers / arabic

// AAP
// model specific entier range!
// TRIPLE CHECK ALL FORMS AND EDM
// Permits
// final arabic
// brochures

// test form
// fix scroll gap vh mobi
// isolate built scripts

// home pages
// drm / sms
// confirm salesforce working
// test all edm / generuc and backend with arabic etc
// !!fill in brochure list each region

import React from 'react';
import ReactDOM from 'react-dom';

// providers
import OneGraphProvider from './providers/OneGraph';
import ChoiceProvider from './providers/Choice';
import ConfigProvider from './providers/Config';
import CheckoutProvider from './providers/Checkout';
import ThanksProvider from './providers/Thanks';
import Router from './providers/Router';

// styles 
import Styles from './theme/global';

const data = {
	"vendor": "theproject",
	"app": "ramadan",
    "arabic": false,
    // "region":"dubai",
    "department":"new",
    "model":false
    // "model":"e-tron-gt"
};

// let test = window.location.href.indexOf('debug=true') > -1 ? true:false

ReactDOM.render(
    // test &&
    <OneGraphProvider>
        <ConfigProvider value={data}>
            <Styles/>
            <ChoiceProvider>
                <CheckoutProvider>
                    <ThanksProvider>
                        <Router/>
                    </ThanksProvider>
                </CheckoutProvider>
            </ChoiceProvider>
        </ConfigProvider>
    </OneGraphProvider>,
  	document.getElementById('root')
);
