import { Base64 } from 'js-base64';

const Provider = (payload,settings) => {

    const credentials = settings.production ? {
        code:'HGHFwj1fsP8ChBqVJDo2',
        identifier:'HEnRlGJv',
        pass:'9345p7p1Xmzt0CTvdYYnVr)-'
    } : {
        code:'BqbGQAwboXJcA2IXgbtI',
        identifier:'3135b12c',
        pass:'75HfjnMpdP6q764Ad6f88e${'
    };

    const ref = Math.round(Math.random() * (2147483647 - 0) + 0);

    let data = {
        'command':'PURCHASE',
        'access_code':credentials.code,
        'merchant_identifier':credentials.identifier,
        'merchant_reference':ref,
        'amount':payload.deposit*100,
        'currency':'AED',
        'language':payload.language,
        'customer_email':payload.email,
        'phone_number':payload.phone,
        'order_description':`Audi Online Order - ${payload.model} - ${payload.vin}`,
        'merchant_extra':Base64.encode(JSON.stringify(payload)).toString('base64').replace(/=+$/, '')
    }

    function encodeSignature(){
        function sha256(ascii){
            function rightRotate(value, amount) {
                return (value>>>amount) | (value<<(32 - amount));
            };
            let mathPow = Math.pow,
                maxWord = mathPow(2, 32),
                lengthProperty = 'length',
                i, j,
                result = '',
                words = [],
                asciiBitLength = ascii[lengthProperty]*8,
                hash = sha256.h = sha256.h || [],
                k = sha256.k = sha256.k || [],
                primeCounter = k[lengthProperty],
                isComposite = {};
                for (let candidate = 2; primeCounter < 64; candidate++) {
                    if (!isComposite[candidate]) {
                        for (i = 0; i < 313; i += candidate) {
                            isComposite[i] = candidate;
                        }
                        hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
                        k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
                    }
                }
            
            ascii += '\x80'; 
            while (ascii[lengthProperty]%64 - 56) ascii += '\x00' 
            for (i = 0; i < ascii[lengthProperty]; i++) {
                j = ascii.charCodeAt(i);
                if (j>>8) return;
                words[i>>2] |= j << ((3 - i)%4)*8;
            }
            words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
            words[words[lengthProperty]] = (asciiBitLength)
            
            // process each chunk
            for (j = 0; j < words[lengthProperty];) {
                let w = words.slice(j, j += 16),
                    oldHash = hash;
                    hash = hash.slice(0, 8);
                    
                    for (i = 0; i < 64; i++) {
                        let w15 = w[i - 15], w2 = w[i - 2],
                            a = hash[0], e = hash[4],
                            temp1 = hash[7]
                            + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) 
                            + ((e&hash[5])^((~e)&hash[6])) 
                            + k[i]
                            + (w[i] = (i < 16) ? w[i] : (
                                    w[i - 16]
                                    + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) 
                                    + w[i - 7]
                                    + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) 
                                )|0
                            );
                        let temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) 
                            + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); 
                        hash = [(temp1 + temp2)|0].concat(hash);
                        hash[4] = (hash[4] + temp1)|0;
                    }
                    
                    for (i = 0; i < 8; i++) {
                        hash[i] = (hash[i] + oldHash[i])|0;
                    }
            }
            
            for (i = 0; i < 8; i++) {
                for (j = 3; j + 1; j--) {
                    let b = (hash[i]>>(j*8))&255;
                    result += ((b < 16) ? 0 : '') + b.toString(16);
                }
            }
            return result;
        }
        let string = '';
        Object.entries(data).sort().forEach((el) => {
            string += `${el[0]}=${el[1]}`;
        })
        let toHash = credentials.pass+string+credentials.pass,
            signature = sha256(toHash);
        
        return signature;
    }

    const form = document.createElement("form");
    form.setAttribute('action',`https://${!settings.production ? 'sb':''}checkout.payfort.com/FortAPI/paymentPage`);
    form.setAttribute('method','post');
    form.setAttribute('id','payment-form');
    Object.entries(data).forEach((item) => {
        const input = document.createElement("input");
            input.setAttribute('type','hidden');
            input.setAttribute('readonly','true');
            input.setAttribute('name',item[0]);
            input.setAttribute('value',item[1]);
            form.appendChild(input);
    })
    const signature = document.createElement("input");
        signature.setAttribute('type','hidden');
        signature.setAttribute('readonly','true');
        signature.setAttribute('name','signature');
        signature.setAttribute('value',encodeSignature(payload));
        form.appendChild(signature);
    
    if(!document.querySelectorAll('#payment-form').length){
        document.body.appendChild(form);
        form.submit();
    }
    
}
export default Provider;