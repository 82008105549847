import { createContext } from 'react';
export const Config = createContext();
export const Choice = createContext({
    choice:{
        slug:null,
        name:null,
        tag:null,
        single:null,
        price:false,
        generic:false,
        link:{
            en:null,
            ar:null
        }
    },
    setChoice: () => {},
    clearChoice: () => {}
});
export const Thanks = createContext({
    thanks:false,
    setThanks: () => {}
});
export const Checkout = createContext({
    car:null,
    setCheckout: () => {},
    clearCheckout: () => {},
});