import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { regionLoader, arabicLoader } from './loaders';

import Page from "../pages/Page";
import Redirect from "../pages/Redirect";
import Transaction from '../pages/Transaction';

// routes
const router = createBrowserRouter([
    {
        path: "/",
        element: <Redirect />,
        errorElement: <Redirect />
    },
    {
        path: ":region",
        element: <Page/>,
        loader: regionLoader
    },
    {
        path: ":region/:lang",
        element: <Page/>,
        loader: arabicLoader
    },
    {
        path: "/transaction/success/en",
        element: <Transaction mode="success"/>,
    },
    {
        path: "/transaction/error/en",
        element: <Transaction mode="error"/>,
    },
    {
        path: "/transaction/success/ar",
        element: <Transaction mode="success" arabic={true}/>,
    },
    {
        path: "/transaction/error/ar",
        element: <Transaction mode="error" arabic={true}/>,
    },
]);

function Provider(){
	return (
		<RouterProvider router={router}/>
	);
}

export default Provider;
