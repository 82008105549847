import styled from "styled-components";
import './global.css';

const Styled = styled.div`
    width:100%;
    box-sizing:border-box;
    background:#f2f2f2;

    --success:#090;
    --error:#ff2959;
    --warning:#e19600;
    --red: #f50537;
    --red-hover: #d4002d;

    --ease:cubic-bezier(0.750, 0.020, 0.500, 1.000);
    
    * {
        box-sizing:border-box;
    }
    
    .info {
        padding:20px 16px 0; 
        @media (min-width:540px){
            padding:24px 36px 0;
        }
        @media (min-width:1920px){
            padding:24px calc((100% - 1920px) / 2) 0px;
        }
    }

    .list {
        width:100%;
        column-gap:12px;
        padding:0 16px 24px;
        scroll-padding:0 32px;
        overflow-x:scroll;
        cursor:${props => props.overflow ? 'grab':'auto'};
        scroll-snap-type: x mandatory;
        @media (min-width:540px){
            column-gap:20px;
            padding:0 32px 24px;
        }
        @media (min-width:768px){
            scroll-snap-type: none;  
            padding:0 32px 32px;
        }
        @media (min-width:1920px){
            padding:0 calc((100% - 1920px) / 2) 20px;
            scroll-padding:0 calc((100% - 1920px) / 2);
        }
        &.hide {
            display:none;
        }
        &.active {
            cursor:${props => props.overflow ? 'grabbing':'auto'};
        }
    }
    
    .model {
        background:white;
        padding:14px 14px 20px;
        scroll-snap-align: start;
        flex-basis:${props => props.total === 1 ? '100%':'calc(100% - 20px)'};
        @media (min-width:540px){
            flex-basis:70%;
        }
        @media (min-width:768px){
            flex-basis:calc(50% - 32px);
        }
        @media (min-width:1024px){
            flex-basis:30%;
        }
        @media (min-width:1440px){
            flex-basis:22%;
        }
        .thumb {
            overflow: hidden;
            width: 100%;
            height:auto;
            aspect-ratio:16/9;
            cursor: pointer;
            background-color: #e5e5e5;
            position: relative;
            @media (min-width:1440px){
                aspect-ratio:16/10;
            }
            &:before {
                display: block;
                content: "";
                position: absolute;
                width: 200%;
                height: 100%;
                animation: 1.5s loading linear 0.5s infinite;
                background: linear-gradient(90deg, transparent, #d9d9d9, transparent);
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                transform: translateX(-100%);
                z-index: 1;
            }
            img {
                width:100%;
                height:100%;
                object-fit: cover;
                opacity:${props => props.loaded ? 1:0};
                display:block;
                transition:.4s opacity cubic-bezier(0.850, 0.020, 0.500, 1.000);
            }
            .badge {
                position:absolute;
                top:0;
                left:0;
                margin:10px;
                padding:6px 9px;
                background:var(--success);
                span {
                    color:white;
                    font-size:12px;
                    line-height:1;
                }
                &.reserved {
                    background:var(--warning);
                }
                &.vin {
                    background:#000;
                    top:auto;
                    bottom:0;
                }
            }
        }
        .detail {
            padding:0 6px;
            .btn-group {
                gap:5px;
                [role="dialog"]{
                    max-width:280px;
                }
            }
        }
        &.loaded {
            .thumb {
                img {
                    opacity:1;
                }
                &:before {
                    display:none;
                }
            }
        }
        &.reserved {
            .thumb {
                cursor: default;
            }
        }
        .active & {
            .thumb {
                cursor: grabbing;
            }
        }
    }

    .checkout {
        background:white;
        padding:0;
        margin:auto;
        height:auto;
        @media (min-width:1024px){
            height:100%;
        }
        .inner {
            height:auto;
            overflow: hidden;
            @media (min-width:1024px){
                height:100%;
            }
        }
        .media {
            overflow: hidden;
            width: 100%;
            height:auto;
            background-color: #f2f2f2;
            position: relative;
            order:1;
            @media (min-width:1024px){
                height:100%;
                order:1;
            }
            .checkout-actions {
                position: absolute;
                top:0;
                right:0;
                margin:0;
                width:100%;
                z-index:9;
                .drag-info {
                    padding: 0 20px;
                    position: relative;
                    top: -2px;
                }
                .close {
                    cursor: pointer;
                    appearance:none;
                    outline:0;
                    border:0;
                    padding:0;
                    margin:2px 4px;
                    background:transparent;
                }
            }
            img {
                width:100%;
                height:100%;
                object-fit: contain;
                display:block;
                transition:.4s opacity cubic-bezier(0.850, 0.020, 0.500, 1.000);
                &.cover {
                    object-fit:cover;
                }
            }
            .carousel {
                width:100%;
                height:100%;
                overflow:scroll;
                scroll-snap-type: x mandatory;
                @media (min-width:1024px){
                    cursor: default;
                    scroll-snap-type:none;
                    overflow:hidden;
                }
                .item {
                    width:100%;
                    height:100%;
                    scroll-snap-align: start;
                    padding-top:24px;
                    &.cover {
                        padding-top:0;
                    }
                    @media (min-width:1024px){
                        padding-top:0;
                        position: absolute;
                        opacity:0;
                        visibility: hidden;
                        transition: opacity .8s ease,visibility .8s ease;
                    }
                    &.active {
                        @media (min-width:1024px){
                            opacity:1;
                            visibility: visible;
                        }   
                    }
                }
            }
        }
        .content {
            height:auto;
            overflow:hidden;
            padding:0 24px;
            position: relative;
            order:2;
            @media (min-width:1024px){
                order:1;
                min-width:440px;
                max-width:520px;
                height:100%;
                overflow:auto;
            }
            .close {
                cursor: pointer;
                appearance:none;
                outline:0;
                border:0;
                padding:0;
                margin:12px -12px 0;
                background:transparent;
            }
            .content-inner {
                padding:22px 0 44px;
                height:auto;
                @media (min-width:1024px){
                    padding:12px 0 64px;
                }
            }
            .intro {
                width:100%;
            }
            .summary-table {
                width:100%;
                .text-right {
                    text-align: right;
                }
                .pad-right {
                    padding-left:32px;
                }
            }

            input[type="checkbox"]{
                height:24px!important;
            }
            
            form {
                width:100%;
            
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active  {
                    box-shadow: 0 0 0 60px white inset !important;
                    background-color: white!important;
                    background-clip: content-box !important;
                }

                input {
                    line-height:24px!important;
                    height:auto!important;
                }

                input,select,button {
                    outline:0!important;
                }

                input,select {
                    padding-right:0!important;
                    padding-left:0!important;
                }

                input[disabled],select[disabled],label[disabled]{
                    cursor: not-allowed!important;
                }

                div[readonly]{
                    &:after {
                        content:'';
                        width: 100%;
                        height: 1px;
                        margin-top: -1px;
                        background:#808080;
                    }
                }

                .haserror {
                    label {
                        color: white;
                    }
                    div div:after {
                        border-bottom-color: var(--error);
                    }
                    #email__invalid-message {
                        color: white;
                        svg {
                            color:var(--error)
                        }
                    }
                }

                [id*="invalid-message"],
                [id*="error-message"] {
                    color: white;
                    svg {
                        color:var(--error)
                    }
                }
                
                // code
                .code {
                    label {
                        cursor: not-allowed!important;
                        span {
                            display: none;
                        }
                    }
                    input {
                        color:inherit!important;
                        opacity:1;
                    }
                    > div > div > div:after {
                        border-color:#808080;
                    }
                }

                // hide phone err
                #phone__invalid-message {
                    display:none;
                }
                
            }

            label[for*="consent"] {
                font-size:12px!important;
                line-height:1.5!important;
                align-self:center;
                span {
                    font-weight:bold;
                    appearance: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .checkout-btns {
                button {
                    background:var(--red);
                    border-color:var(--red);
                    color:white;
                    &[disabled]:not(.processing),
                    &:not([disabled]):hover{
                        background:var(--red-hover);
                        border-color:var(--red-hover);
                    }
                }
            }

            .error-text {
                color:var(--red);
            }
            
            .btn-group {
                gap:6px;
                button {
                    white-space:nowrap;
                    &.red {
                        background:var(--red);
                        border-color:var(--red);
                        color:white;
                        &:hover{
                            background:var(--red-hover);
                            border-color:var(--red-hover);
                        }
                    }
                }
            }
        }
    }

    .drawer {
        height: 100vh!important; 
        height: calc(var(--vh, 1vh) * 100)!important;
        overflow:scroll;
        @media (min-width:1024px){
            overflow:hidden;
        }
    }

    .loader {
        height:300px;
    }

    ${props => props.arabic && `
        .drag-swipe {
            svg {
                transform:scaleX(-1);
            }
        }
        .checkout .content .summary-table .pad-right {
            padding-right:32px;
            padding-left:0;
        }
        .checkout .content .summary-table .text-right {
            text-align:left;
        }
        form {
            .code-wrapper {
                flex-direction: row-reverse;
                input {
                    direction:ltr;
                    text-align: right;
                }
            }
        }
    `}
`;
export default Styled;
