import axios from "axios";

const Provider = (payload,settings) => {
    axios({
        method: 'post',
        responseType: 'json',
        url: `${settings.offline ? 'http://localhost:3000':'https://api.audimiddleeast.com/payments'}/${!settings.production ? 'staging/':''}abudhabi/payment.php`,
        data: {...payload,url:`${settings.offline ? 'https://payment.dev':'https://api.audimiddleeast.com/payments'}`},
        headers: {
            'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
        }
    }).then((res)=>{
        // console.log(res.data)
        window.location.href = res.data.url;
    }).catch((err)=>{
        alert('Something went wrong. Please try again later');
        console.error(err.response.data.msg)
    })
}
export default Provider;