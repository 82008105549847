import React, { useState } from 'react';

// context
import { Thanks } from '../context';

export default function Provider({children}){
    const [thanks,setState] = useState(false);
    
    const setThanks = (thanks) => {
        setState(thanks)
    }

    const context = {
        thanks:thanks,
        setThanks:setThanks
    }
    
    return (
        <Thanks.Provider value={context}>
            { children }
        </Thanks.Provider>
    )
}