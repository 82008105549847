import axios from "axios";

const Provider = (payload,settings) => {
    axios({
        method: 'post',
        responseType: 'json',
        url: `${settings.offline ? 'http://localhost:3000':'https://api.audimiddleeast.com/payments'}/${!settings.production ? 'staging/':''}jordan/payment.php`,
        data: {
            ...payload,
            orderID:Math.floor(Math.random() * 9999) + 1111
        },
        headers: {
            'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
        }
    }).then((res)=>{
        window.location.href = res.data.url;
    }).catch((err)=>{
        console.log(err.response.data.msg);
    })
}
export default Provider;