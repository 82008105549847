import React, { useState } from 'react';

import useBreaks from '../hooks/useBreaks';
import useArabic from '../hooks/useArabic';

import { AudiPlatformProvider, ThemeProvider, audiDarkTheme, Layout, LayoutItem, Text, Button } from '@audi/audi-ui-react';

import parse from 'html-react-parser';

import Styled from '../modules/thanks/styles';

import locale from '../json/locale.json';

export default function Page({mode,arabic}){ 

    const mq = useBreaks();
    const lang = arabic ? 'ar':'en';

    const [show,setShow] = useState(false); 

	return (
		<AudiPlatformProvider>
			<ThemeProvider theme={audiDarkTheme}>
                <div dir={arabic ? 'rtl':'ltr'}>
                    <Layout className="ramadan transaction" wrap="wrap">
                        <Styled as={LayoutItem} loaded={show} generic={true}>
                            <div className="media">
                                <picture>
                                    <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.avif`}/>
                                    <img src={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.jpg`} alt={`Audi Ramadan`} onLoad={()=>setShow(true)}/>
                                </picture>
                            </div>
                            <Layout className="content">
                                <Layout direction="column" justify="start" align="center" spacing={['xxxl','m',null]}>
                                    <Text as="h1" variant={mq.tablet ? 'order3':'order1'}>
                                        {
                                            mode === 'success' ?
                                            parse(locale.transaction.success.title[lang])
                                            : 
                                            parse(locale.transaction.error.title[lang])
                                        }
                                    </Text> 
                                    <Text as="h2" variant={mq.tablet ? 'copy1':'order4'} spacing={[mq.tablet ? 's':'s',null,'l']}>
                                        {
                                            mode === 'success' ?
                                            locale.transaction.success.body[lang]
                                            : 
                                            locale.transaction.error.body[lang]
                                        }
                                    </Text> 
                                    {
                                        mode !== 'success' && <Button variant="text" size="medium" href="/">{locale.transaction.error.cta[lang]}</Button>
                                    }
                                </Layout>
                            </Layout>
                        </Styled>
                    </Layout>
                </div>
			</ThemeProvider>
		</AudiPlatformProvider>
	)
}