import styled from 'styled-components';
import { tablet } from '../../theme/mixins/index.js';

const Styled = styled.div`
    position: absolute;
    height: 32px;
    width: 32px;
    display: inline-block;
    animation: none;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    svg {
        transform:rotate(0deg) scaleX(1);
        &.stop-animation {
            animation:none!important;
        }
    }
    .circle-start {
        stroke:rgba(255,255,255,.2);
        stroke-dasharray: 227;
        stroke-dashoffset: 227;
        transition:stroke-dashoffset .7s ease;
    }
    .circle {
        stroke-dasharray: 227;
        stroke-dashoffset: 227;
        transition:stroke-dashoffset .7s .4s ease;
    }
    .circle-end {
        stroke-dasharray: 227;
        stroke-dashoffset: 227;
        transform:rotate(200deg);
        transform-origin: center;
    }
    .check-icon {
        &:after{
            position: absolute;
            content: "";
            top: 50%;
            left: 50%;
            transform:rotate(45deg);
            height: 7px;
            width: 7px;
            margin: -5px 0 0 -6px;
            border-top: 2px solid currentColor;
            border-right: 2px solid currentColor;
            transition:transform .2s ease, opacity .2s ease;
            transform:scale(.8) rotate(135deg);
            opacity:0;
        }
    }
    ${props => (props.busy || props.sent) && `
        transform:translate(-50%,-50%);
        svg {
            animation: rotate 2s ease infinite forwards;
            ${tablet`
                animation: rotate 2s .5s ease infinite forwards;
            `};
        }
        .circle-start {
            stroke-dasharray: 227;
            stroke-dashoffset: 0;
            transition:stroke-dashoffset .7s ease;
        }
        .circle {
            stroke-dashoffset: 160;
        }
        .check-icon{
            &:after{
                transform:scale(.8) rotate(135deg);
                opacity:0;
            }
        }
    `}
    ${props => props.sent && `
        .circle {
            stroke-dashoffset: 210;
        }
        .circle-end {
            animation: centerCircle .5s .7s linear forwards;
        }
        .check-icon{
            &:after{
                height: 0;
                width: 0;
                border-width:0;
                margin: 0px 0 0 -8px;
                transform:scaleX(-1) rotate(135deg);
                transform-origin: left top;
                transition:none;
                animation: check-icon 1.4s .8s forwards ease;
            }
        }
    `}

    @keyframes rotate {
        100%{
            transform:rotate(360deg) scaleX(1);
        }
    }
    @keyframes check-icon {
        0%{
            border-width:2px;
            height: 0;
            width: 0;
            opacity: 1;
        }
        20%{
            border-width:2px;
            height: 0;
            width: 6px;
            opacity: 1;
        }
        40%{
            border-width:2px;
            height: 12px;
            width: 6px;
            opacity: 1;
        }
        100%{
            border-width:2px;
            height: 12px;
            width: 6px;
            opacity: 1;
        }
    }

    @keyframes centerCircle{
        0%{
            stroke-dasharray: 0 227;
            stroke-dashoffset: 114;
        }
        100%{
            stroke-dasharray: 227 0;
            stroke-dashoffset: 227;
        }
    }

`;

export default Styled;