import React, { useState } from 'react';

// context
import { Choice } from '../context';

export default function Provider({children}){
    const [choice,setState] = useState({
        slug:null,
        name:null,
        single:null,
        price:false,
        generic:false,
        link:{
            en:null,
            ar:null
        }
    });
    
    const setChoice = (choice) => {
        setState(choice)
    }

    const clearChoice = () => {
        setState({
            slug:null,
            name:null,
            single:null,
            price:false,
            generic:false,
            link:{
                en:null,
                ar:null
            }
        })
    }

    const context = {
        choice:choice,
        setChoice:setChoice,
        clearChoice
    }
    
    return (
        <Choice.Provider value={context}>
            { children }
        </Choice.Provider>
    )
}