import React, { useState } from 'react';

// context
import { Checkout } from '../context';

export default function Provider({children}){
    const [checkout,setState] = useState(null);
    
    const setCheckout = (checkout) => {
        setState(checkout)
    }

    const clearCheckout = () => {
        setState(null)
    }

    const context = {
        checkout:checkout,
        setCheckout:setCheckout,
        clearCheckout:clearCheckout
    }
    
    return (
        <Checkout.Provider value={context}>
            { children }
        </Checkout.Provider>
    )
}