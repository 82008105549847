import styled from 'styled-components';
import { tablet, mid } from '../../theme/mixins/index.js';

const Styled = styled.div`
    overflow: hidden;
    position: relative;
    width:100%;
    background:black;
    scroll-snap-align: start;
    .media {
        z-index:0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top:0;
        left:0;
        img {
            width:100%;
            height:100%;
            object-fit: cover;
            opacity:${props => props.loaded ? 1:0};
            display:block;
            transition:.4s opacity cubic-bezier(0.850, 0.020, 0.500, 1.000);
        }
    }

    .content {
        position:relative;
        background:rgba(0,0,0,.8);

        .contained {
            padding:42px 0;
            ${tablet`
                padding:66px 0;
            `};
            ${mid`
                padding:72px 0;
            `};
        }

        h1,h2 {
            text-align:center;
        }

        h1 {
            span {
                display:inline-block;
                font-size:min(5.5vw,28px);
                line-height:1.4;
                ${tablet`
                    font-size:min(6vw,36px);
                    line-height:1.4;
                `}
            }
        }
    }

    .form {
        form {
            width:100%;
            max-width:840px;

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active  {
                box-shadow: 0 0 0 60px var(--white) inset !important;
                background-color: var(--white)!important;
                background-clip: content-box !important;
            }

            input,select,button {
                outline:0!important;
            }

            input,select {
                padding-right:0!important;
                padding-left:0!important;
            }

            select option {
                background-color:#000;
            }

            input {
                line-height:24px!important;
                height:auto!important;
                &[type="checkbox"]{
                    height:24px!important;
                }
            }

            input[disabled],select[disabled],label[disabled]{
                cursor: not-allowed!important;
            }

            div[readonly]{
                &:after {
                    content:'';
                    width: 100%;
                    height: 1px;
                    margin-top: -1px;
                    background:#808080;
                }
            }

            .form-row {
                p {
                    text-align:left;
                }
            }

            .haserror {
                label {
                    color: white;
                }
                div div:after {
                    border-bottom-color: var(--error);
                }
                #email__invalid-message {
                    color: white;
                    svg {
                        color:var(--error)
                    }
                }
            }

            [id*="invalid-message"],
            [id*="error-message"] {
                color: white;
                svg {
                    color:var(--error)
                }
            }

            // hide phone err
            #phone__invalid-message {
                display:none;
            }

            // code
            .code {
                label {
                    cursor: not-allowed!important;
                    span {
                        display: none;
                    }
                }
                input {
                    color:#D9D9D9!important;
                    opacity:1;
                }
                > div > div > div:after {
                    border-color: #B3B3B3;
                }
            }
            // consent
            label[for*="consent"] {
                font-size:12px!important;
                line-height:1.5!important;
                align-self:center;
            }

            .is-readonly {
                cursor: not-allowed;
                label {
                    color:#666666!important;
                    cursor: not-allowed!important;
                }
                [readonly]{
                    cursor: not-allowed;
                    select {
                        cursor: not-allowed;
                    }
                    &:after {
                        display: block;
                        box-sizing: border-box;
                        width: 100%;
                        height: 2px;
                        margin-top: -1px;
                        border-bottom-color: #808080;
                        border-bottom-width: 1px;
                        border-bottom-style: solid;
                        -webkit-transition-timing-function: cubic-bezier(0.75,0.02,0.5,1);
                        transition-timing-function: cubic-bezier(0.75,0.02,0.5,1);
                        -webkit-transition-duration: 0.25s;
                        transition-duration: 0.25s;
                        -webkit-transition-property: border-bottom-color,background-color;
                        transition-property: border-bottom-color,background-color;
                        content: '';
                    }
                }
            }
            
            // honeypot
            .field.message {
                display:none;
                opacity:0;
                visibility: hidden;
            }

            #submit {
                background:var(--red);
                border-color:var(--red);
                color:white;
                width:auto!important;
                transition:border-color .3s var(--ease),  background .3s var(--ease), color .3s var(--ease);
                &[disabled]:not(.processing),
                &:not([disabled]):hover{
                    background:var(--red-hover);
                    border-color:var(--red-hover);
                }
                .circle-start {
                    stroke:rgba(0,0,0,.2);
                }
                > div {
                    position: relative;
                    overflow:unset;
                    width:0!important;
                    margin-right:0px;
                    transition:width .4s ease,margin-right .4s ease;
                }
                &.processing {
                    > div {
                        margin-right:10px;
                        width:32px!important;
                    }
                }
                span {
                    margin-inline-start:0!important;
                }
            }

        }
    }

    .bank-logo {
        width:140px;
        margin:14px 0 0;
        img {
            width:100%;
            display:block;
        }
    }

    [dir="rtl"] & {
        .form {
            form {
                .code-wrapper {
                    flex-direction: row-reverse;
                    input {
                        direction:ltr;
                        text-align: right;
                    }
                }
                .form-row {
                    p {
                        text-align:right;
                    }
                }
                #submit {
                    &.processing {
                        > div {
                            margin-right:0;
                            margin-left:10px;
                        }
                    }
                }
            }
        }
    }

`;

export default Styled;
