import styled from "styled-components";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Modal = styled(Popup)`
    &-content {
        border:0;
        padding:0;
        width:100vw!important;
        max-width:800px;
        @media(min-width:768px){
            width:94vw;
        }
        * {
            box-sizing: border-box;
        }
        .no-bullet {
            &:before {
                display: none!important;
            }
        }
        .modal {
            .close {
                appearance: none;
                background:transparent;
                position: absolute;
                top:0;
                right:0;
                outline:0;
                border:0;
                cursor: pointer;
                padding:0;
                margin:2px 2px 0 0;
                @media(min-width:768px){
                    margin:10px 10px 0 0;
                }
                svg {
                    cursor: pointer;
                    color:black;
                }
            }
            .inner {
                max-height:100vh;
                overflow:auto;
                .body {
                    padding:24px 44px 100px 24px;
                    @media(min-width:768px){
                        padding:28px 62px 58px 28px;
                    }
                    h1 {
                        margin-bottom:10px;
                    }
                    ul {
                        padding-left:22px;
                        @media(min-width:768px){
                            padding-left:36px;
                        }
                    }
                    p,[role="listitem"]{
                        font-size:12px;
                        margin:10px 0;
                        line-height: 1.5;
                        @media(min-width:768px){
                            font-size:16px;
                            margin:20px 0;
                        }
                    }
                }
            }
            &.arabic {
                * {
                    font-family: AudiType,AR,Verdana,Tahoma,sans-serif!important;
                }
                ul {
                    [role="listitem"]{
                        &:before {
                            left:auto;
                            right:0;
                            transform:translateX(100%);
                        }
                    }
                }
            }
        }
    }
`;
export default Modal;
