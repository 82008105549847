import React, { useContext, useEffect } from 'react';

import { Config, Choice, Thanks as TY } from '../context';
import useRegion from '../hooks/useRegion';
import useOffer from '../hooks/useOffer';
import useArabic from '../hooks/useArabic';

import { AudiPlatformProvider, ThemeProvider, audiDarkTheme, Layout } from '@audi/audi-ui-react';

// modules
import Models from '../modules/models';
import Form from '../modules/form';
import Thanks from '../modules/thanks';

export default function Page(){ 

    const { model, department } = useContext(Config);
    const { setChoice } = useContext(Choice);

    const { allModels } = useOffer();
    const { thanks } = useContext(TY);
    const region = useRegion();
    const { arabic, lang } = useArabic();



    useEffect(() => {
        if(model){
            const single = allModels.find(item => item.value === model);
            if(single){
                setChoice({
                    slug:single.value,
                    name:single.name,
                    tag:single.tag,
                    price:single.price ? single.price[lang]:false,
                    generic:single.generic ? true:false,
                    link:single.link,
                    single:true
                });
            }else {
                const singleRange = region.data.entirefleet.find(item => item.value === model);
                if(singleRange){
                    setChoice({
                        slug:model,
                        name:singleRange.name,
                        tag:false,
                        price:false,
                        generic:true,
                        single:true,
                        link:false
                    });
                }
            }
        }
    }, [model])
        
    useEffect(() => {
        const vUnit = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        vUnit();
        window.addEventListener('resize', vUnit);
    }, [])

    useEffect(() => {
        if(region && !model){
            if(arabic){
                document.title = `موديلات رمضان من Audi | Audi ${region.data.ar.name}`;
                document.querySelector('meta[name="description"]').setAttribute('content',`تحقق من موديلات Audi ${region.data.ar.name} الجديدة المستوحاة من رمضان واكتشف مجموعة من الفرص الرائعة لبدء مستقبل رائع. التقدم. رمضان مصدر الإلهام`);
            }else {
                document.title = document.title.replace('Middle East',region.data.en.name);
                document.querySelector('meta[name="description"]').setAttribute('content',document.querySelector('meta[name="description"]').getAttribute('content').replace('Middle East',region.data.en.name));
            }
        }
    }, [region,model])

	return (
        region &&
		<AudiPlatformProvider>
			<ThemeProvider theme={audiDarkTheme}>
                <div dir={arabic ? 'rtl':'ltr'}>
                    <Layout className={`ramadan ${model ? 'solo-form':''}`} wrap="wrap">
                    {
                        thanks ? 
                        <Thanks single={model ? true:false}/>
                        :
                        <>
                        { (department === 'new' && !model) && <Models/>}
                        <Form/>   
                        </>
                    }
                    </Layout>
                </div>
			</ThemeProvider>
		</AudiPlatformProvider>
	)
}