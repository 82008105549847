import React, { useState, useEffect, useRef } from "react";

import Styled from './styles';

const Module = ({busy, sent}) => {

    const [stop,setStop] = useState(false);

    const svg = useRef();

    useEffect(() => {
        if(svg.current){
            const animationHandle = () => {
                if(sent){
                    setStop(true);
                }
            }
            svg.current.addEventListener("animationiteration",animationHandle);
        }

    }, [sent,svg])

    return (
        <Styled busy={busy} sent={sent}>
            <svg viewBox="0 0 80 80" className={stop ? 'stop-animation':''} ref={svg}>
                <circle className="circle-start" cx="40" cy="40" r="36" fill="transparent" strokeWidth="4"/>
                <circle className="circle" cx="40" cy="40" r="36" fill="transparent" stroke="currentColor" strokeWidth="4"/>
                <circle className="circle-end" cx="40" cy="40" r="36" fill="transparent" stroke="currentColor" strokeWidth="4"/>
            </svg>
            <div className="check-icon"></div>
        </Styled>
    )
}

export default Module;
