import runDubai from './regions/Dubai.jsx';
import runAbudhabi from './regions/Abudhabi.jsx';
import runQatar from './regions/Qatar.jsx';
import runJordan from './regions/Jordan.jsx';

export default function usePayment(payload,settings){
    const Provider = (region) => {
        if(region === 'dubai'){
            return runDubai(payload,settings);
        }
        if(region === 'abudhabi'){
            return runAbudhabi(payload,settings);
        }
        if(region === 'qatar'){
            return runQatar(payload,settings);
        }
        if(region === 'jordan'){
            return runJordan(payload,settings);
        }
    }
    return { processPayment:Provider }
}
