// globalStyles.js
import { createGlobalStyle } from 'styled-components';
import { mid } from './mixins/index.js';
 
const GlobalStyle = createGlobalStyle`
    html.fa,body.fa {
        margin:0;
        background:black;
        > iframe {
            display:none;
        }
    }
    .ramadan {
        width:100%;
        margin:auto;
        background:white;
        column-gap:12px;
        row-gap:10px;
        margin:0;
        scroll-snap-type: y mandatory;
        overflow: auto;
        overflow-x:hidden;
        overflow-y:scroll;
        height: 100vh; 
        height: calc(var(--vh, 1vh) * 100);

        ${mid`
            height:auto;
            overflow: auto;
            scroll-snap-type: unset;
        `}

        &.solo-form {
            height:auto;
            overflow:hidden;
            scroll-snap-align:none;
        }
        
        --red: #f50537;
        --red-hover: #d4002d;
        --success:#090;
        --error:#ff2959;
        --warning:#e19600;

        --ease:cubic-bezier(0.750, 0.020, 0.500, 1.000);

        * {
            box-sizing: border-box;
        }

        .block {
            display:block;
        }

        /* animation */
        @keyframes loading {
            0% {
                transform: translateX(-100%);
            }
            60% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(100%);
            }
        }
    }

    .error,.redirect {
        width:100vw;
        height:100vh;
        background:black;
    }

    [dir="rtl"] .ramadan {
        * {
            font-family:AudiType,AR,Verdana,Tahoma,sans-serif!important;
        }
    }
    
`;
 
export default GlobalStyle;