import React from 'react';

import Styles from './styles';

export default function Module({children,...props}){
    return ( 
        <Styles {...props}>
            <div className="contained">
                {children}
            </div>
        </Styles>
    );
}