import axios from "axios";

const Provider = (payload,settings) => {
    const loadScript = (callback) => {
        const exists = document.getElementById('qatar-script');
        if(!exists){
            const script = document.createElement('script');
            script.src =  `https://${!settings.production ? 'test-dohabank.mtf.':'dohabank.'}gateway.mastercard.com/static/checkout/checkout.min.js`;
            script.id = 'qatar-script';
            script.setAttribute('data-error','errorCallback');
            script.setAttribute('data-cancel','errorCancel');
            script.setAttribute('data-complete','paymentComplete');
            document.head.appendChild(script);  
            
            window.errorCallback = (error) => {
                console.error('error',error);
            }
            window.errorCancel = () => {
                console.log('cancelled');
            }
            window.paymentComplete = (data) => {
                console.log('worked',data);
            }

            script.onload = () => { 
                if (callback) callback();
            };
        }
        if (exists && callback) callback();
    };
    
    loadScript(() => {
        axios({
            method: 'post',
            responseType: 'json',
            url: `${settings.offline ? 'http://localhost:3000':'https://api.audimiddleeast.com/payments'}/${!settings.production ? 'staging/':''}qatar/payment.php`,
            data: {...payload,orderID:Math.floor(Math.random() * 9999) + 1111,url:`${settings.offline ? 'https://payment.dev':'https://api.audimiddleeast.com/payments'}`},
            headers: {
                'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
            }
        }).then((res)=>{
            let Checkout = window.Checkout;
            Checkout.configure({
                session: { 
                    id:  res.data.id
                },
                interaction: {
                    merchant: {
                        name: 'Q-AUTO'
                    },
                    displayControl:{
                        customerEmail:"HIDE",
                        billingAddress:"HIDE",
                        shipping:"HIDE"
                    }
                }
            });
            Checkout.showPaymentPage()
        }).catch((err)=>{
            console.error(err.response.data.msg)
        }) 
    });

}
export default Provider;
