import styled from 'styled-components';
import { mobi, tablet, mid, desktop } from '../../theme/mixins/index.js';

const Styles = styled.div`
    padding:0 16px;
    ${mobi`
        padding:0 28px;
    `};
    ${tablet`
        padding:0 40px;
    `};
    ${mid`
        padding:0 60px;
    `};
    ${desktop`
        padding:0 96px;
    `};
`;
export default Styles;