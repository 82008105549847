import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useStock(region,models){

    const [stock,setStock] = useState(false);

    const url = `https://api.audimiddleeast.com/models/vtp/${region}`;

    useEffect(() => {
        if(region && typeof models !== 'string'){
            const getStock = async () => {
                const stock = await axios({
                    method: 'post',
                    responseType: 'json',
                    url: url,
                    data:{
                        models:typeof models === 'object' ? models:[]
                    },
                    headers: {
                        'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT',
                        'content-type':'application/json'
                    }
                });
                if(stock.status === 200){
                    setStock(stock.data);
                }
            }
            if(!stock){
                getStock();
            }
        }
    }, [stock,url,region,models])

    if(!region){
        console.error('Please add a region to the useStock hook');
    }
    if(typeof models === 'string'){
        console.error('Please use an array from models in the useStock hook');
    }
    
    return stock
}
