import useArabic from './useArabic';
import useRegion from './useRegion';

export default function useOffer(){

    const { lang } = useArabic();
    const region = useRegion().offers;

    const offer = region.offers[lang];
    const models = region.models.filter(model => !model.basic);
    const otherModels = region.models.filter(model => model.basic);
    const allModels = region.models;

    return { 
        offer: (offer && offer.length > 0) ? offer:false, 
        models:(models && models.length > 0) ? models:false,
        otherModels:(otherModels && otherModels.length > 0) ? otherModels:false,
        allModels:(allModels && allModels.length > 0) ? allModels:false
    };
}
