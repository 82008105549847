import { json, redirect } from 'react-router-dom';
import regions from '../../json/regions.json';

export async function regionLoader({ params }){
    if(!regions.hasOwnProperty(params.region)){
        return redirect(`/`);
        // throw new Response(null, { status: 404 });
    }
    if(!regions[params.region].live){
        window.location.href = regions[params.region].en.link.replace('en/','en.html')+'?utm_source=ramadan-redirect&utm_campaign=ramadan-2023';
    }
    const lang = navigator.language.indexOf('ar') > -1 ? 'ar':'en';
    return redirect(`/${params.region}/${lang}`);
    // return json({ region:params.region, lang:'en' }, { status: 200 });
}

export async function arabicLoader({ params }){
    if(!regions.hasOwnProperty(params.region)){
        return redirect(`/`);
        // throw new Response(null, { status: 404 });
    }
    if(!regions[params.region].live){
        window.location.href = regions[params.region].en.link.replace('en/','en.html')+'?utm_source=ramadan-redirect&utm_campaign=ramadan-2023';
    }
    if(params.lang !== 'en' && params.lang !== 'ar'){
        return redirect(`/${params.region}/en`);
    }
    return json({ region:params.region, lang: params.lang }, { status: 200 });
}