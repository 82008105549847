import React, { useState, useContext, useEffect } from 'react';

import { Config, Choice, Thanks } from '../../context';

import useRegion from '../../hooks/useRegion';
import useOffer from '../../hooks/useOffer';
import useArabic from '../../hooks/useArabic';
import useBreaks from '../../hooks/useBreaks';

import sendGoogleEvent from '../../providers/helpers/events';

import { useInView } from 'react-intersection-observer';

import { Layout, LayoutItem, Text, FormGroup, TextField, Select, Checkbox, Button } from '@audi/audi-ui-react';

import Container from '../container';
import Progress from '../progress';

import Styled from './styles';

import axios from 'axios';
import isEmail from 'email-validator';
import parse from 'html-react-parser';

import locale from '../../json/locale.json';

export default function Module(){

    const { department } = useContext(Config);

    const { choice, setChoice } = useContext(Choice);
    const { setThanks } = useContext(Thanks);

    const { allModels } = useOffer();
    const { lang } = useArabic();
    const mq = useBreaks();

    const region = useRegion();
    const cities = region.data[lang].cities;

    const hasPDF = (excludes,value) => {
        return !excludes.includes(value);
    }

    const [show,setShow] = useState(false); 
        
    const [ready,setReady] = useState(false),
        [busy,setBusy] = useState(false),
        [sent,setSent] = useState(false),
        [emailErr,setEmailErr] = useState(false),
	    [phoneErr,setPhoneErr] = useState(false),
        [phoneLength,setPhoneLength] = useState({min:0,max:11}),
        [form, setForm] = useState({
			model:department === 'used' ? 'Unspecified_Audi':choice.single ? choice.slug : '',
			title:'',
			name:'',
			surname:'',
			email:'',
			phone:'',
			country:region.data.en.country,
			city:cities.length > 1 ? '':cities[0].value,
			consent:'no',
            consentEmail:'no',
            consentSMS:'no',
            modelLink:choice.single ? `${region.data[lang].link}models/${choice.link[lang]}` : false,
            brochureLink:(choice.single && hasPDF(region.data[lang].noPDF,choice.slug)) ? `${region.data[lang].brochure}${choice.slug}.pdf` : false,
            stockLink:`${region.data.en.link}new-cars.html`,
            genericModel:choice.single ? choice.generic:false,
            honeypot:''
		});

    const phoneMsg = region.data[lang].format;
    const modelOptions = [...!choice.single ? allModels : allModels.filter(model => model.value === choice.slug)];
    modelOptions.sort((a, b) => a.order - b.order);
    
    // fn
    useEffect(() => {
        if(department === 'new'){
            if(choice.slug){
                setForm(f => ({
                    ...f, 
                    model: choice.slug,
                    modelLink:choice.link ? `${region.data[lang].link}models/${choice.link[lang]}`:false,
                    brochureLink:hasPDF(region.data[lang].noPDF,choice.slug) ? `${region.data[lang].brochure}${choice.slug}.pdf`:false,
                    genericModel:choice.generic ? true:false,
                }));
            }else {
                setForm(f => ({
                    ...f, 
                    model: '',
                    modelLink:false,
                    brochureLink:false,
                    genericModel:false
                }));
            }
        }
    }, [choice,lang,region.data,department])

    const onChange = (e) => {

		let { name,value } = e.currentTarget;
		if(name === 'message'){
            form['honeypot'] = value;
        }else {
            form[name] = value;
        }
        if(name.indexOf('consent') > -1){
            if(value === 'yes'){
                form[name] = 'no';
            }else {
                form[name] = 'yes';
            }
        }
        if(name === 'model'){
            const model = region.offers.models.find(model => model.value === value);
            if(model){
                setChoice({
                    slug:model.value,
                    name:model.name,
                    tag:model.tag,
                    price:model.price ? model.price[lang]:false,
                    generic:model.generic ? true:false,
                    single:false,
                    link:model.link
                });
            }else {
                const fromRange = region.data.entirefleet.find(model => model.value === value);
                setChoice({
                    slug:value,
                    name:fromRange.name,
                    tag:false,
                    price:false,
                    generic:true,
                    single:false,
                    link:false
                });
            }
        }
		setForm({...form});
        validate(name,value);
	}

    const validate = (name,value) => {

        const validateNumber = (number) => {
            if(region.data.en.country === 'United Arab Emirates'){
                setPhoneLength({min:9,max:9})
                if(number.length === 9 && number.match("^5")){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Bahrain'){
                setPhoneLength({min:6,max:8})
                if(number.length > 5 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Kuwait'){
                setPhoneLength({min:6,max:10})
                if(number.length > 5 && number.length < 11){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Saudi Arabia'){
                setPhoneLength({min:7,max:11})
                if(number.length > 6){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Oman'){
                setPhoneLength({min:8,max:8})
                if(number.length === 8){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Qatar'){
                setPhoneLength({min:7,max:8})
                if(number.length > 6 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Jordan'){
                setPhoneLength({min:8,max:9})
                if((number.length === 8 && number.match('^6')) || (number.length === 9 && number.match('^7'))){
                    return true;
                }else {
                    return false;
                }
            }else if(region.data.en.country === 'Lebanon'){
                setPhoneLength({min:7,max:8})
                if(number.length > 6 && number.length < 9){
                    return true;
                }else {
                    return false;
                }
            }
        }
        
        const empty = Object.entries(form).filter(item => item[0] !== 'honeypot' && item[1] === '').length ? true:false,
            email = !isEmail.validate(Object.entries(form).find(item => item[0] === 'email')[1]),
            phone = !validateNumber(Object.entries(form).find(item => item[0] === 'phone')[1]);
            
            // now errors on specific items
            if(name === 'email'){
                if(email && value !== ''){
                    setEmailErr(true)
                }else {
                    setEmailErr(false)
                }
            }
            if(name === 'phone'){
                if(phone && value !== ''){
                    setPhoneErr(true)
                }else {
                    setPhoneErr(false)
                }
            }
            // return
            if(!empty && !email && !phone){
                setReady(true);
                return false;
            }
            setReady(false)

	}
    
    const onSubmit = () => {
        if(ready){

            setBusy(true);
            sendGoogleEvent('Ramadan 2023', `Click - Form submit`, `Form - Enquiry form`);  

            // params
			let params = new URLSearchParams(window.location.search),
                entries = params.entries(),
                formparams = '',
				utmStorage = localStorage.getItem('saved_utm');
			if(utmStorage){
				let utm = JSON.parse(utmStorage),
					entries = utm.query.split('&');

				for(var entry of entries){
					if(entry.indexOf('utm_') > -1){
						formparams += '['+entry+']';
					}
				} 
			}else {
				for(const entry of entries){
					if(entry[0] !== 'scroll' && entry[0] !== 'm' && entry[0] !== 'r' && entry[0] !== 'configurator' && entry[0] !== 'carline' && entry[0] !== 'dealer' && entry[0] !== 'audicode' && entry[0] !== 'modelcode' && entry[0] !== 'price'){
						formparams += '['+entry[0]+'='+entry[1]+']';
					}
				}
			}

            axios({
		        method: 'post',
		        responseType: 'json',
		        url: 'https://rest.audimiddleeast.com/ramadan/2023/process.php',
		        data: {
                    category:department,
                    model:form.model,
                    country:form.country,
				    city:form.city,
		        	title:form.title,
				    name:form.name,
				    surname:form.surname,
				    email:form.email,
				    phone:form.phone,
                    audicode:form.audicode,
					language:lang,
			  		consent:form.consent,
                    consentEmail:form.consentEmail,
                    consentSMS:form.consentSMS,
                    modelLink:form.modelLink,
                    brochureLink:form.brochureLink,
                    stockLink:form.stockLink,
                    genericModel:form.genericModel,
                    parameters:formparams,
					referrer:document.referrer,
                    honeypot:form.honeypot
			  	},
			  	headers: {
			  		'authorization':'7e44fd4bf570f256ce66fdd2f3a5d13PRJT'
			  	}
		    }).then((res)=>{
		    	if(res.data === 'success'){
		    		setBusy(false);
                    setSent(true);
                    setTimeout(()=>{
                        setThanks(true);
                    },2000);
                    sendGoogleEvent('Ramadan 2023', `Click - Form sent`, `Form - Enquiry form`);  
		    	}else {
		    		alert("Somethings not right: please try again later");
                    sendGoogleEvent('Ramadan 2023', `Click - Form error`, `Form - Enquiry form`);  
                    window.location.reload();
		    	}
		    }).catch((err)=>{
                console.error(err)
		    	alert("Somethings not right: please try again later");
                sendGoogleEvent('Ramadan 2023', `Click - Form error`, `Form - Enquiry form`);  
                window.location.reload();
		    }) 
        }
    }

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    });
    
    useEffect(() => {
        if(inView){
            sendGoogleEvent('Ramadan 2023', `Impression - Form`, `Form - Enquiry form`);  
        }
    }, [inView])

	// render
    return (
        <Styled loaded={show} className="form" ref={ref}>
            <div className="media">
                <picture>
                    <source type="image/avif" srcSet={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.avif`}/>
                    <img src={`${process.env.PUBLIC_URL}/assets/generic/thanks/${mq.mid ? 'mobi':'banner'}.jpg`} alt={`Ramadan Banner`} onLoad={()=>setShow(true)}/>
                </picture>
            </div>
            <Layout className="content" direction="column" justify="center">
                <Container>
                    <Text as="h1" variant={mq.mid ? 'order2':'order1'}>
                        <span>
                        {
                            region.slug === 'oman' ?
                            <>{parse(lang === 'ar' ? '<b>احصل على السيارة وابدأ في تسديد</b> <br/>الأقساط في رمضان المقبل':'<b>Buy your new Audi now,</b> <br/>and start paying next Ramadan.')}</>
                            :<><b>{choice.tag ? locale.tag[choice.tag][lang]:locale.tag.generosity[lang]}</b> <br/>
                            {locale.tag.body[lang]}</>
                        }
                        </span>
                    </Text> 
                    <Text as="h2" variant={mq.mid ? 'copy1':'order4'} spaceStackStart="s">
                        {
                        region.data.sayallmodels ? 
                            <>
                            {choice.price ? choice.price:locale.all[lang]}
                            <br/>{locale.form.heading[lang]}
                            </>
                        :
                        <>
                            {choice.price ? <>{choice.price} <br/></>:''}
                            {locale.form.heading[lang]}
                        </>
                        }
                    </Text>
                    <Layout className="form" justify="center" spaceStackStart="xxl">
                        <form>
                            <Layout className="wrapper" direction="column">
                                <Layout className="form-row" justify="between" wrap="wrap" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                    {
                                        department === 'new' &&
                                        <LayoutItem className="field" basis="50%" grow="1">
                                            <Select
                                                inputId="model"
                                                name="model"
                                                label={locale.form.model[lang]}
                                                value={form.model}
                                                required
                                                invalid={false}
                                                spaceInlineEnd={mq.mobi ? 'xs':'s'}
                                                onChange={onChange}
                                                >
                                                {
                                                    region.data.entirefleet ?
                                                    region.data.entirefleet.map((model,i) => (
                                                        <option value={model.value} key={i}>Audi {model.name}</option>
                                                    ))
                                                    :
                                                    modelOptions.map((model,i) => (
                                                        <option value={model.value} key={i}>Audi {model.name}</option>
                                                    ))
                                                }
                                            </Select>
                                        </LayoutItem>
                                    }
                                    <LayoutItem className="field" basis="50%">
                                        <Select
                                            inputId="title"
                                            name="title"
                                            label={locale.form.title[lang]}
                                            value={form.title}
                                            required
                                            invalid={false}
                                            spaceInlineStart={department === 'new' ? 's':null} 
                                            spaceInlineEnd={department === 'used' ? mq.mobi ? 'xs':'s':null}
                                            onChange={onChange}
                                            >
                                            <option value="mr">Mr</option>
                                            <option value="mrs">Mrs</option>
                                            <option value="miss">Miss</option>
                                        </Select>
                                    </LayoutItem>
                                </Layout>
                                <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                    <LayoutItem className="field" basis="50%" grow="1">
                                        <TextField
                                            inputMode="text"
                                            type="text"
                                            inputId="name"
                                            name="name"
                                            label={locale.form.name[lang]}
                                            value={form.name}
                                            required
                                            invalid={false}
                                            spaceInlineEnd={mq.mobi ? 'xs':'s'}
                                            onChange={onChange}
                                            />
                                    </LayoutItem>
                                    <LayoutItem className="field" basis="50%" grow="1">
                                        <TextField
                                            inputMode="text"
                                            type="text"
                                            inputId="surname"
                                            name="surname"
                                            label={locale.form.surname[lang]}
                                            value={form.surname}
                                            required
                                            invalid={false}
                                            spaceInlineStart={mq.mobi ? 'xs':'s'}
                                            onChange={onChange}
                                            />
                                    </LayoutItem>
                                </Layout>
                                <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'} wrap="wrap">
                                    <LayoutItem className="field" basis={mq.mobi ? '100%':'50%'} grow="1">
                                        <TextField
                                            className={emailErr ? 'haserror':''}
                                            inputMode="email"
                                            type="email"
                                            inputId="email"
                                            name="email"
                                            label={emailErr ? locale.form.email.error[lang]:locale.form.email[lang]}
                                            value={form.email}
                                            required
                                            spaceInlineEnd={mq.mobi ? null:'s'}
                                            spaceStackEnd={mq.mobi ? 'm':null}
                                            invalid={emailErr ? true:false}
                                            onChange={onChange}
                                            />
                                    </LayoutItem>
                                    <LayoutItem className="field" basis={mq.mobi ? '100%':'50%'} grow="1">
                                        <FormGroup>
                                            <Layout className="code-wrapper">
                                                <LayoutItem basis="70px" shrink="1" className="code" spaceInlineStart={mq.mobi ? null:'s'}>
                                                    <TextField
                                                        inputMode="text"
                                                        type="text"
                                                        inputId="code"
                                                        name="code"
                                                        label={'Code'}
                                                        value={`+${region.data[lang].code}-`}
                                                        disabled
                                                        />
                                                </LayoutItem>
                                                <LayoutItem basis="100%" spaceInlineStart={mq.mobi ? null:'s'}>
                                                    <TextField
                                                        className={phoneErr ? 'haserror':''}
                                                        inputMode="tel"
                                                        type="tel"
                                                        pattern="[0-9.]*"
                                                        maxLength={phoneLength.max}
                                                        minLength={phoneLength.min}
                                                        inputId="phone"
                                                        name="phone"
                                                        label={phoneErr ? phoneMsg:locale.form.phone[lang]}
                                                        value={form.phone}
                                                        required
                                                        invalid={phoneErr ? true:false}
                                                        onChange={(e)=>{
                                                            let val = e.currentTarget.value;
                                                            if(!isNaN(val)){
                                                                onChange(e)
                                                            }
                                                        }}
                                                        />
                                                </LayoutItem>
                                            </Layout>
                                        </FormGroup>
                                    </LayoutItem>
                                </Layout>
                                {
                                    cities.length > 1 &&
                                    <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                        <LayoutItem className="field" basis={mq.mobi ? '100%':'50%'}>
                                            <Select
                                                inputId="city"
                                                name="city"
                                                label={locale.form.city[lang]}
                                                value={form.city}
                                                required
                                                invalid={false}
                                                spaceInlineEnd={mq.mobi ? null:'s'}
                                                onChange={onChange}
                                                >
                                                {
                                                    cities.map((city,k)=>{
                                                        return <option value={city.value} key={k}>{city.name}</option>	
                                                    })
                                                }
                                            </Select>
                                        </LayoutItem>
                                    </Layout>
                                }

                                {/* honey pot */}
                                <Layout className="form-row">
                                    <LayoutItem className="field message">
                                        <TextField
                                            inputMode="text"
                                            type="text"
                                            inputId="message"
                                            name="message"
                                            label="Message"
                                            value={form.honeypot}
                                            required
                                            invalid={false}
                                            onChange={onChange}
                                            />
                                    </LayoutItem>
                                </Layout>
                                
                                <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                    <LayoutItem grow="0" shrink="0" basis="100%">
                                    {
                                        region.slug === 'abudhabi' ?
                                        <>
                                            <Text variant="copy2" spaceStackEnd="s">{locale.form.consent[lang]}</Text>
                                            <Layout wrap="wrap">
                                            <Checkbox 
                                                inputId="consentEmail" 
                                                name="consentEmail" 
                                                value={form.consentEmail}
                                                required
                                                onChange={onChange}
                                                spaceInlineEnd="l"
                                                spaceStackEnd="s"
                                                >
                                                {locale.form.consent.email[lang]}
                                            </Checkbox>
                                            <Checkbox 
                                                inputId="consentSMS" 
                                                name="consentSMS" 
                                                value={form.consentSMS}
                                                required
                                                onChange={onChange}
                                                spaceStackEnd="s"
                                                >
                                                {locale.form.consent.sms[lang]}
                                            </Checkbox>
                                            </Layout>
                                        </>
                                        : 
                                        <Checkbox 
                                            inputId="consent" 
                                            name="consent" 
                                            value={form.consent}
                                            required
                                            onChange={onChange}
                                            >
                                            {locale.form.consent[lang]}
                                        </Checkbox>
                                    }
                                    </LayoutItem>
                                </Layout>
                                <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                    <LayoutItem grow="0" shrink="0" basis="100%">
                                        <Button 
                                            variant="primary" 
                                            disabled={(ready && !busy && !sent) ? false:true}
                                            id="submit"
                                            className={busy || sent ? 'processing':''}
                                            icon={<Progress busy={busy} sent={sent}/>}
                                            onClick={onSubmit}
                                            >
                                            {
                                                busy ? locale.form.submit.processing[lang]
                                                : sent ? locale.form.submit.sent[lang]
                                                :locale.form.submit[lang]
                                            }
                                        </Button>
                                    </LayoutItem>
                                </Layout>
                                <Layout className="form-row" justify="between" spaceStackEnd={mq.mobi ? 'm':'l'}>
                                    <LayoutItem grow="0" shrink="0" basis="100%">
                                        <Text as="p" variant="copy2" spaceStackEnd="s">
                                            {
                                                department === 'used' && region.data[lang].usedterms && region.data[lang].usedterms.length ?
                                                region.data[lang].usedterms.map((term,i) => (
                                                    <span key={i} className="block">{term}</span>
                                                ))
                                                :region.data[lang].terms.map((term,i) => (
                                                    <span key={i} className="block">{term}</span>
                                                ))
                                            }
                                        </Text>
                                        <Text as="p" variant="copy3" id="fineprint">
                                            {locale.form.fine[lang]} <a href="mailto:rsvp@audi.avme.ae" style={{display:'inline-block'}}>rsvp@audi.avme.ae</a>
                                        </Text>
                                        {
                                            region.slug === 'oman' &&
                                            <div className="bank-logo">
                                                <img src={`${process.env.PUBLIC_URL}/assets/logo/oman.png`} alt=""/>
                                            </div>
                                        }
                                    </LayoutItem>
                                </Layout>
                            </Layout>
                        </form>
                    </Layout>
                </Container>
            </Layout>
        </Styled>
	);
}